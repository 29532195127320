import { AnimatedSection, BaseButton } from '../../../../components';
import {
  ArrowRight,
  GoalsGirls,
  PeopleInGlasses,
  StakeAndWin,
  TravelLovers,
} from '../../../../assets';
import { Link } from 'react-router-dom';
import React from 'react';

export const AchieveGoals = () => {
  return (
    <section className="flex flex-col gap-14 main_wrapper">
      <div className="flex flex-col gap-3 ">
        <AnimatedSection>
          <h1 className="heading-900  w-full items-center text-[#1A1C1F] text-center text-[32px] leading-[42px] md:text-5xl md:leading-[58px] tracking-[-1.47px]">
            Achieve all your set <br /> goals with
            <span className="text-[var(--primary)]"> Fluxtribe</span> app
          </h1>
        </AnimatedSection>
        <AnimatedSection>
          <p className="md:text-xl text-sm leading-6 md:leading-[26px] tracking-[2%] text-center text-[#545861]">
            Find Your Tribe. Connect to Win. Achieve Your Goal.
          </p>
        </AnimatedSection>
      </div>
      <div className="md:pt-[46px] flex flex-col gap-[86px] md:gap-[120px]">
        {/* FROM OUR COMMUNITIES */}
        <div className="flex flex-col-reverse gap-16 md:justify-between lg:flex-row">
          <AnimatedSection>
            <div className="w-full flex flex-col gap-5 text-center lg:max-w-[498px] lg:text-start">
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-4">
                  <p className="text-[var(--blue)] font-bold text-base leading-[23.12px]">
                    FROM OUR COMMUNITIES
                  </p>
                  <h2 className="heading-700 text-[26px] md:text-[40px] leading-9 md:leading-[56px] text-[#1A1C1F] tracking-[0.015em]">
                    Join existing community or create your own and invite your
                    friends to join
                  </h2>
                </div>
                <p className="leading-8 text-sm md:text-base tracking-[0.02em]">
                  Walk your journey with friends. Find and connect with those
                  who share similar goals with you and watch the power of
                  community do the magic!
                </p>
              </div>
              <Link to="https://merifot.enyata.com/auth/login" target="_blank">
                <BaseButton
                  variant="outline"
                  className="max-w-[256px] w-full flex gap-[14px] items-center mx-auto lg:mx-0"
                >
                  Let’s help you get started <img src={ArrowRight} alt="" />
                </BaseButton>
              </Link>
            </div>
          </AnimatedSection>
          <AnimatedSection>
            <img
              src={PeopleInGlasses}
              alt=""
              className="w-full h-auto lg:max-w-[570px] object-center"
            />
          </AnimatedSection>
        </div>

        {/* CREATE GOALS AND ACHIEVE GREATNESS */}
        <div className="flex flex-col gap-16 md:justify-between lg:flex-row">
          <AnimatedSection>
            <img
              src={GoalsGirls}
              alt=""
              className="w-full h-auto lg:max-w-[570px] object-center"
            />
          </AnimatedSection>
          <AnimatedSection>
            <div className="w-full flex flex-col gap-5 text-center lg:max-w-[498px] lg:text-start">
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-4">
                  <p className="text-[var(--blue)] font-bold text-base leading-[23.12px]">
                    CREATE GOALS AND ACHIEVE GREATNESS
                  </p>
                  <h2 className="heading-700 text-[30px] md:text-[40px] leading-[42px] md:leading-[56px] text-[#1A1C1F] tracking-[0.015em]">
                    Set goals and track progress in fun and easy ways
                  </h2>
                </div>
                <p className="leading-8 text-sm md:text-base tracking-[0.02em]">
                  No more stopping half-way. Explore exciting ways to achieve
                  your goals faster while tracking your progress all the way.
                </p>
              </div>
              <Link to="https://merifot.enyata.com/auth/login" target="_blank">
                <BaseButton
                  variant="outline"
                  className="max-w-[256px] w-full flex gap-[14px] items-center mx-auto lg:mx-0"
                >
                  Let’s help you get started <img src={ArrowRight} alt="" />
                </BaseButton>
              </Link>
            </div>
          </AnimatedSection>
        </div>

        {/* ENGAGEMENTS AND CHATS */}
        <div className="flex flex-col-reverse gap-16 justify-between lg:flex-row">
          <AnimatedSection>
            <div className="w-full flex flex-col gap-5 text-center lg:max-w-[498px] lg:text-start">
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-4">
                  <p className="text-[var(--blue)] font-bold text-base leading-[23.12px]">
                    ENGAGEMENTS & CHATS{' '}
                  </p>
                  <h2 className="heading-700 text-[30px] md:text-[40px] leading-[42px] md:leading-[56px] text-[#1A1C1F] tracking-[0.015em]">
                    Do more with your community by Engagement and chat
                  </h2>
                </div>
                <p className="leading-8 text-sm md:text-base tracking-[0.02em]">
                  Enhance your network and amplify your social influence by
                  actively engaging in discussions on topics you find
                  interesting within your communities; start a conversation or
                  join existing ones
                </p>
              </div>
              <Link to="https://merifot.enyata.com/auth/login" target="_blank">
                <BaseButton
                  variant="outline"
                  className="max-w-[256px] w-full flex gap-[14px] items-center mx-auto lg:mx-0"
                >
                  Let’s help you get started <img src={ArrowRight} alt="" />
                </BaseButton>
              </Link>
            </div>
          </AnimatedSection>

          <AnimatedSection>
            <img
              src={TravelLovers}
              alt=""
              className="w-full h-auto lg:max-w-[570px] object-center"
            />
          </AnimatedSection>
        </div>

        {/* STAKE AND WIN PRIZES */}
        <div className="flex flex-col gap-16 md:justify-between lg:flex-row">
          <AnimatedSection>
            <img
              src={StakeAndWin}
              alt=""
              className="w-full h-auto lg:max-w-[570px] object-center"
            />
          </AnimatedSection>
          <AnimatedSection>
            <div className="w-full flex flex-col gap-5 text-center lg:max-w-[498px] lg:text-start">
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-4">
                  <p className="text-[var(--blue)] font-bold text-base leading-[23.12px]">
                    STAKE & WIN PRIZES
                  </p>
                  <h2 className="heading-700 text-[30px] md:text-[40px] leading-[42px] md:leading-[56px] text-[#1A1C1F] tracking-[0.015em]">
                    Achieve your goal, snag cash rewards—your community's way,
                    your agreed rules!
                  </h2>
                </div>
                <p className="leading-8 text-sm md:text-base tracking-[0.02em]">
                  Fuel your drive, achieve goals faster! Earn cash rewards from
                  your community and enjoy friendly competition.
                </p>
              </div>
              <Link to="https://merifot.enyata.com/auth/login" target="_blank">
                <BaseButton
                  variant="outline"
                  className="max-w-[256px] w-full flex gap-[14px] items-center mx-auto lg:mx-0"
                >
                  Let’s help you get started <img src={ArrowRight} alt="" />
                </BaseButton>
              </Link>
            </div>
          </AnimatedSection>
        </div>
      </div>
    </section>
  );
};
