import { Blog, ManOnPhone, Blog1, Blog2, Blog3, WomanSmiling } from '../assets';

export function useBlogs() {
  const blogs = {
    topArticle: {
      img: Blog,
      details: {
        category: 'Company',
        heading:
          'Discover the inspiring stories, practical tips, and community triumphs that define Fluxtribe’s journey to success. Join us on a quest to unlock your full potential and turn aspirations into achievements. ',
        description:
          'Embarking on the Path to Success: Unveiling the Stories, Strategies, and Community Triumphs of Fluxtribe. ',
        author: 'Ethan Mitchell',
        date: '14 Dec, 2023',
        authorImage: ManOnPhone,
      },
    },
    mostRead: [
      {
        id: 1,
        image: Blog1,
        description: `In today's fast-paced world, juggling multiple responsibilities and goals can often feel like an uphill battle. Sometimes, you just forget to keep track of certain milestones and activities and eventually get overwhelmed by the enormity of the amount of work that is left to be done.  
Let's dive into how integrating FluxTribe into your daily routine can streamline your workflow and set you up for success.
Picture this: You've been dreaming of fitting into your favorite pair of jeans, exploring exotic destinations, or mastering a new skill. 
With FluxTribe's intuitive goal-setting features, you can transform these aspirations into tangible objectives. Whether it's breaking down your weight loss journey into manageable milestones, mapping out your travel itinerary, or outlining your study schedule, FluxTribe makes goal setting easy and fun. FluxTribe's goal-setting feature is your ticket to turning dreams into actionable plans.
Imagine being part of a supportive community where you can share your progress, seek advice, and celebrate victories together.
Through FluxTribe's community feature, you'll regularly engage with fellow tribe members, checking in on your goals and offering support along the way. Plus, with check-in submissions reviewed by two members from different goals within the community, you'll benefit from diverse perspectives and constructive feedback. Ever heard the saying, "It takes a village"? Well, FluxTribe embodies this philosophy by connecting you with a tribe of like-minded individuals from across the globe.




Picture this scenario: You've set a goal to run a marathon, but you're struggling to stay consistent with your training.
Enter FluxTribe's staking feature, where you can wager on your goal alongside other tribe members. As you progress towards your goal, timely check-ins, correct submissions, and successful reviews earn you a chance to win a share of the staked money. It's a win-win situation – stay motivated while potentially earning cash rewards for your efforts. Everyone knows that staying motivated is key to achieving our goals, and FluxTribe takes it up a notch with its staking and cash reward feature. But remember, staking is optional, so you can choose the level of commitment that works for you.
Whether you're aiming to climb the ranks or simply seeking inspiration from others, FluxTribe's leaderboard feature provides a platform to inspire and be inspired. Imagine seeing your name among the top performers, celebrating your achievements, and connecting with individuals who share your drive for success.FluxTribe's leaderboard feature is your ticket to connecting with fellow high achievers and drawing inspiration from their successes. 
Incorporating FluxTribe into your daily routine isn't just about setting goals – it's about transforming your aspirations into tangible achievements, connecting with a supportive community, staying motivated with incentives, and drawing inspiration from fellow high achievers. So why wait? Streamline your workflow, integrate FluxTribe into your daily routine, and embark on the journey towards your most ambitious goals today!
`,
        author: 'James Thomson',
        date: '04 October, 2023',
        heading:
          'Streamline Your Workflow: Integrating FluxTribe with Your Daily Routine',
        facebookUrl: 'https://www.facebook.com',
        linkedinUrl: 'https://www.linkedin.com',
        authorImage: ManOnPhone,
        category: 'company',
      },
      {
        id: 2,
        image: Blog2,
        description: `If you've ever felt overwhelmed by the sheer number of goals you want to achieve, you're not alone. Whether it's losing weight, traveling the world, advancing in your career, or simply finding more time for yourself, the journey from aspiration to accomplishment can often feel like an uphill battle. But fear not, because FluxTribe is here to make that journey smoother and more rewarding than ever before.
Set Goals Seamlessly
FluxTribe isn't just another goal-setting app – it's your personal companion on the path to success. With its intuitive features, FluxTribe makes it easy to set and track your goals, no matter how big or small they may be. Whether you're aiming to run a marathon or simply read more books, FluxTribe provides the tools you need to turn your dreams into actionable plans.
Connect with a Tribe
Achieving your goals doesn't have to be a solo endeavor. With FluxTribe, you can connect with a supportive community of like-minded individuals who share your aspirations. Whether you're seeking advice, encouragement, or simply a friendly face to cheer you on, your FluxTribe is there to support you every step of the way.
Track Your Progress
Accountability is key to success, and FluxTribe is committed to helping you stay on track towards your goals. Through regular check-ins and feedback from your fellow tribe members, you'll have the support and encouragement you need to keep pushing forward, even when the going gets tough.
Win Exciting Rewards
And what better way to stay motivated than with the promise of exciting rewards? With FluxTribe, you can stake on your goals and win cash prizes for your achievements. It's a fun and interactive way to stay committed to your goals and celebrate your successes along the way.
Connect with High Achievers
In addition to connecting with your tribe, FluxTribe also allows you to connect with high achievers from around the world. With the leaderboard feature, you can see how you stack up against others in your community and draw inspiration from their successes.
Turning Dreams into Reality
With FluxTribe by your side, turning your dreams into reality has never been easier. Whether you're aiming to lose weight, travel the world, or simply live a happier and more fulfilled life, FluxTribe provides the tools, support, and motivation you need to make it happen.
So what are you waiting for? Download FluxTribe today and start turning your dreams into reality!

`,
        author: 'James Thomson',
        date: '04 October, 2023',
        heading:
          'From Dreams to Reality: Making Your Goals Attainable with FluxTribe',
        facebookUrl: 'https://www.facebook.com',
        linkedinUrl: 'https://www.linkedin.com',
        authorImage: ManOnPhone,
        category: 'How To',
      },
    ],
    recentlyAdded: [
      {
        id: 1,
        image: Blog1,
        description: `In today's fast-paced world, juggling multiple responsibilities and goals can often feel like an uphill battle. Sometimes, you just forget to keep track of certain milestones and activities and eventually get overwhelmed by the enormity of the amount of work that is left to be done.  
Let's dive into how integrating FluxTribe into your daily routine can streamline your workflow and set you up for success.
Picture this: You've been dreaming of fitting into your favorite pair of jeans, exploring exotic destinations, or mastering a new skill. 
With FluxTribe's intuitive goal-setting features, you can transform these aspirations into tangible objectives. Whether it's breaking down your weight loss journey into manageable milestones, mapping out your travel itinerary, or outlining your study schedule, FluxTribe makes goal setting easy and fun. FluxTribe's goal-setting feature is your ticket to turning dreams into actionable plans.
Imagine being part of a supportive community where you can share your progress, seek advice, and celebrate victories together.
Through FluxTribe's community feature, you'll regularly engage with fellow tribe members, checking in on your goals and offering support along the way. Plus, with check-in submissions reviewed by two members from different goals within the community, you'll benefit from diverse perspectives and constructive feedback. Ever heard the saying, "It takes a village"? Well, FluxTribe embodies this philosophy by connecting you with a tribe of like-minded individuals from across the globe.




Picture this scenario: You've set a goal to run a marathon, but you're struggling to stay consistent with your training.
Enter FluxTribe's staking feature, where you can wager on your goal alongside other tribe members. As you progress towards your goal, timely check-ins, correct submissions, and successful reviews earn you a chance to win a share of the staked money. It's a win-win situation – stay motivated while potentially earning cash rewards for your efforts. Everyone knows that staying motivated is key to achieving our goals, and FluxTribe takes it up a notch with its staking and cash reward feature. But remember, staking is optional, so you can choose the level of commitment that works for you.
Whether you're aiming to climb the ranks or simply seeking inspiration from others, FluxTribe's leaderboard feature provides a platform to inspire and be inspired. Imagine seeing your name among the top performers, celebrating your achievements, and connecting with individuals who share your drive for success.FluxTribe's leaderboard feature is your ticket to connecting with fellow high achievers and drawing inspiration from their successes. 
Incorporating FluxTribe into your daily routine isn't just about setting goals – it's about transforming your aspirations into tangible achievements, connecting with a supportive community, staying motivated with incentives, and drawing inspiration from fellow high achievers. So why wait? Streamline your workflow, integrate FluxTribe into your daily routine, and embark on the journey towards your most ambitious goals today!
`,
        author: 'James Thomson',
        date: '04 October, 2023',
        heading:
          'Streamline Your Workflow: Integrating FluxTribe with Your Daily Routine',
        facebookUrl: 'https://www.facebook.com',
        linkedinUrl: 'https://www.linkedin.com',
        authorImage: ManOnPhone,
        category: 'company',
      },
      {
        id: 2,
        image: Blog2,
        description: `Goals Attainable with FluxTribe
If you've ever felt overwhelmed by the sheer number of goals you want to achieve, you're not alone. Whether it's losing weight, traveling the world, advancing in your career, or simply finding more time for yourself, the journey from aspiration to accomplishment can often feel like an uphill battle. But fear not, because FluxTribe is here to make that journey smoother and more rewarding than ever before.

Set Goals Seamlessly
FluxTribe isn't just another goal-setting app – it's your personal companion on the path to success. With its intuitive features, FluxTribe makes it easy to set and track your goals, no matter how big or small they may be. Whether you're aiming to run a marathon or simply read more books, FluxTribe provides the tools you need to turn your dreams into actionable plans.

Connect with a Tribe
Achieving your goals doesn't have to be a solo endeavor. With FluxTribe, you can connect with a supportive community of like-minded individuals who share your aspirations. Whether you're seeking advice, encouragement, or simply a friendly face to cheer you on, your FluxTribe is there to support you every step of the way.

Track Your Progress
Accountability is key to success, and FluxTribe is committed to helping you stay on track towards your goals. Through regular check-ins and feedback from your fellow tribe members, you'll have the support and encouragement you need to keep pushing forward, even when the going gets tough.

Win Exciting Rewards
And what better way to stay motivated than with the promise of exciting rewards? With FluxTribe, you can stake on your goals and win cash prizes for your achievements. It's a fun and interactive way to stay committed to your goals and celebrate your successes along the way.

Connect with High Achievers
In addition to connecting with your tribe, FluxTribe also allows you to connect with high achievers from around the world. With the leaderboard feature, you can see how you stack up against others in your community and draw inspiration from their successes.

Turning Dreams into Reality
With FluxTribe by your side, turning your dreams into reality has never been easier. Whether you're aiming to lose weight, travel the world, or simply live a happier and more fulfilled life, FluxTribe provides the tools, support, and motivation you need to make it happen.
So what are you waiting for? Download FluxTribe today and start turning your dreams into reality!

`,
        author: 'James Thomson',
        date: '04 October, 2023',
        heading:
          'From Dreams to Reality: Making Your Goals Attainable with FluxTribe',
        facebookUrl: 'https://www.facebook.com',
        linkedinUrl: 'https://www.linkedin.com',
        authorImage: ManOnPhone,
        category: 'company',
      },
      {
        id: 3,
        image: Blog3,
        description: `In the fast-paced landscape of technological advancement, Fluxtribe stands out as a true embodiment of innovation. With a mission to transform the way we engage with digital solutions, FluxTribe goes beyond conventional boundaries, introducing a realm where creativity meets functionality. 

At the heart of its magic is a commitment to user-centric design and cutting-edge features that redefine the very essence of a mobile application.

The journey into the world of FluxTribe begins with its sleek and intuitive interface, designed to seamlessly integrate into the user's daily life. This isn't just an app; it's a comprehensive solution that understands and anticipates your needs.

 From smart automation that simplifies complex tasks to personalized features that adapt to your preferences, Fluxtribe is a game-changer in the mobile app landscape. Its versatility is showcased through a myriad of applications, making it equally indispensable for professionals seeking enhanced productivity and tech enthusiasts craving an immersive digital experience.

What sets Fluxtribe apart is its meticulous attention to detail in crafting a product that not only meets but exceeds expectations. Every feature is a result of extensive research and development, ensuring that users are equipped with a tool that not only keeps up with the digital era's demands but also anticipates future trends. 

The magic behind Fluxtribe is not just in its functionalities but in its ability to anticipate the evolving needs of users, making it a true companion on the journey of digital transformation.

Step into the future with FluxTribe and unlock the door to a world where innovation isn't just a concept; it's a daily reality. The magic lies not just in what FluxTribe can do for you today, but in how it's poised to shape the landscape of tomorrow's digital experiences. Embrace the power of innovation; embrace FluxTribe.
`,
        author: 'James Thomson',
        date: '04 October, 2023',
        heading:
          'Unleashing the Power of Innovation: Explore the Magic Behind Fluxtribe',
        facebookUrl: 'https://www.facebook.com',
        linkedinUrl: 'https://www.linkedin.com',
        authorImage: WomanSmiling,
        category: 'news',
      },
    ],
  };

  return { blogs };
}
