import React from 'react';
import { Button } from '../Button';
import { BlueBanner, ManOnPhone, TealBanner, WomanSmiling } from '../../assets';
import { AnimatedSection } from '../AnimateSection';
import { Link } from 'react-router-dom';

export const Banner = () => {
  return (
    <AnimatedSection>
      <div className=" flex gap-12 flex-col-reverse sm:flex-col-reverse md:flex-row justify-between max-w-[1240px] m-6 md:m-[auto] py-11 px-4 md:px-11 md:py-9 bg-gradient-to-b from-[#ECF7F6] to-[#D4EDEB] rounded-2xl">
        <div className="grid justify-items-center md:justify-items-start gap-4 ">
          <div className="text-center md:text-left ">
            <h3 className="font-bold font-satoshi text-[32px] mb-3 text-[var(--black-900)]">
              Let’s help get you started on{' '}
              <span className="text-[var(--primary)]">Fluxtribe</span>
            </h3>
            <p className="font-sm text-[var(--dark)]">
              Step into a world of limitless goal-setting and achievements.
            </p>
          </div>
          <Link to="https://merifot.enyata.com/auth/login" target="_blank">
            <Button>Let’s Get Started</Button>
          </Link>
        </div>
        <div className="grid sm:gap-3 md:grid-cols-[225px_225px]">
          <div className="grid gap-3 w-[236px] sm:w-[236px] md:w-full ">
            <img src={WomanSmiling} alt="" />
            <img src={TealBanner} alt="" />
          </div>
          <div className="grid gap-3 justify-self-end  w-[236px] sm:w-[236px] md:w-full ">
            <img src={BlueBanner} alt="" />
            <img src={ManOnPhone} alt="" />
          </div>
        </div>
      </div>
    </AnimatedSection>
  );
};
