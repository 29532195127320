import { FeaturesComponent } from '../../../../components';
import {
  Goals1,
  Goals2,
  Goals3,
  Goals4,
  Goals,
  Goals5,
  Goals6,
} from '../../../../assets';

const GoalSetting = () => {
  const feature = {
    heading: 'Goal setting and progress\ntracking made easy and fun to do',
    coloredText: 'easy and fun',
    description: `Set goals, have fun, and fast-track your achievements together with your tribe. It's that easy.`,
    button: 'Get Started',
    image: Goals,
    gridSection: {
      heading: 'Let’s help you create a new\ngoal with Fluxtribe app',
      coloredText: 'Fluxtribe',
      description:
        'Step into a world of exciting goal-setting and limitless achievements. Discover the fast way to attaining your goals.',
      gridArray: [
        {
          heading: 'Easily Create Your Account and Achieve Your Goals',
          description:
            'Download Fluxtribe app from Google play store or Apple store to create a new account.',
          button: 'Sign up',
          image: { src: Goals1, position: 'bottom' },
        },
        {
          heading: 'Seamlessly Create and Accomplish Your Goals with Friends',
          description:
            'After logging in, go to “Goal menu” on the bottom navigation bar.  select “goal” click on the “+” sign ',
          coloredText: ['“Goal menu”', '“goal”', '“+”'],
          button: 'Create Goal',
          image: { src: Goals2, position: 'top' },
        },
        {
          heading:
            'Interested in creating Public, Private or Goals Goal? The choice is yours!',
          description:
            'You can invite a few friends, open goal to the public or go solo. Select the type of goal you want to create from the 3 available options.',
          button: 'Next',
          image: { src: Goals3, position: 'bottom' },
        },
        {
          heading: 'Set Your Rules To Help Achieve Your Goal',
          description:
            'Fill all necessary information about the goal including the rules guiding the goal you are setting',
          button: 'Next',
          image: { src: Goals4, position: 'center' },
        },
        {
          heading:
            'Set your individual contribution towards a goal. Winner Takes All!',
          description:
            'Make the goal more exciting by staking on the goal with other members. "Note that staking is optional"',
          button: 'Next',
          coloredText: `"Note that staking is optional"`,
          image: { src: Goals5, position: 'center' },
        },
        {
          heading: 'Create Your Goal and Begin Today',
          description:
            'Click on create once all necessary information has been filled then you goal will appear on goals menu and can start inviting others to join the goal.',
          button: 'Next',
          image: { src: Goals6, position: 'center' },
        },
      ],
    },
    joinSection: {
      heading: 'How to join goal on fluxtribe',
      description:
        'Find a Goals that is aiming at the same goal as you and join',
      button: 'Join a goal',
      isStep: true,
      steps: [
        {
          name: 'Go to goal menu',
          description: `Click on ''Goal menu'' at the bottom navigation bar and select ''Goal'' Search for the type of goal you are interested in to join`,
          coloredText: `''Goal menu''`,
        },
        {
          name: 'Join goal',
          description: `Select the goal you want to join and carefully read information about it. Then click on the ''join'' button. You can now join the conversation in the goal chat channel.
          
          
          Note:

          You can not opt out of a goal until the goal is completed or the end date.
          `,
          coloredText: `''join''`,
        },
      ],
    },
  };

  return <FeaturesComponent feature={feature} />;
};

export { GoalSetting };
