import { useEffect, useState } from 'react';
import { Button, BlogsComponent } from '../../../components';
import { useBlogs } from '../../../hooks';
import React from 'react';
import { useNavigate, useLocation } from 'react-router';

// At the required place

export const Blogs = () => {
  const { blogs } = useBlogs();
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { name: 'All', value: 'all' },
    { name: 'News', value: 'news' },
    { name: 'Company', value: 'company' },
    { name: 'How to', value: 'howTo' },
  ];

  const [activeTab, setActiveTab] = useState({ name: 'All', value: 'all' });

  React.useLayoutEffect(() => {
    if (location.hash) {
      const stripped = location.hash.replace('#', '');
      const str = stripped
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, function (str) {
          return str.toUpperCase();
        });
      setActiveTab({
        value: stripped,
        name: str,
      });
    }
  }, [location]);

  useEffect(() => {
    if (activeTab.value !== 'all') {
      navigate(location.pathname + `#${activeTab.value}`);
    } else {
      navigate(location.pathname);
    }
  }, [activeTab.value, location.pathname]);

  return (
    <div className="max-w-[1240px] mx-6 md:m-auto z-10 mt-[56px] md:mt-[90px]">
      <div className="flex gap-2 flex-wrap md:justify-between items-center mb-[70px]">
        <h1 className="text-[#1A1C1F] font-black md:text-[64px] text-4xl whitespace-pre-line leading-[43px] md:leading-[73px]">
          Fluxtribe blog
        </h1>
        <div className="flex  gap-3 flex-wrap md:gap-3 font-satoshi">
          {tabs.map((tab) => (
            <Button
              variant="tab"
              className={`tab${activeTab.value === tab.value ? '-active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab.name}
            </Button>
          ))}
        </div>
      </div>
      <BlogsComponent blogs={blogs} activeTab={activeTab} />
    </div>
  );
};
