import Logo from './Logo.svg'
import ChevronDown from './chevron-down.svg'
import GreenChevronDown from './green-chevron-down.svg'
import BlackChevronDown from './black-chevron-down.svg'
import Apple from './apple.svg'
import Android from './android.svg'
import Minus from './minus-circle.svg'
import Plus from './plus-circle.svg'
import Lightbulb from './lightbulb.png';
import Globe from './globe.png';
import Diamond from './diamond.png';
import ArrowRight from './arrow-right.svg';
import Tick from './Tick.svg';
import Envelope from './envelope.svg';
import LocationSvg from './location.svg';
import TelephoneSvg from './telephone.svg';
import GreenCircle from './green-circle-check.svg';
import Search from './search-refraction.svg';
import Hamburger from './hamburger.svg';
import Close from './close.svg';
import LogoBlack from './logo-black.svg';
import ArrowDownWhite from './arrow-narrow-down.svg';
import LinkedIn from './linkedin.svg';
import Facebook from './facebook.svg';





export {
  Logo,
  ArrowDownWhite,
  LogoBlack,
  BlackChevronDown,
  Facebook,
  LinkedIn,
  Close,
  Hamburger,
  Search,
  GreenChevronDown,
  Lightbulb,
  Globe,
  Diamond,
  ArrowRight,
  Tick,
  Envelope,
  LocationSvg,
  TelephoneSvg,
  ChevronDown,
  Apple,
  Android,
  Plus,
  Minus,
  GreenCircle
}

