import React from 'react';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import {
  Envelope,
  LocationSvg,
  TelephoneSvg,
  WorkingPeople,
} from '../../../assets';
import { AnimatedSection, BaseButton } from '../../../components';
import { BaseInput, Textarea } from '../../../shared';
import { useContactUs } from '../../../hooks';

const contactFormSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  email: z.string().email('Email is required'),
  message: z.string().min(1, 'Message is required'),
});

type contactFormValues = z.infer<typeof contactFormSchema>;

export const ContactUs = () => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<contactFormValues>({
    resolver: zodResolver(contactFormSchema),
    mode: 'onSubmit',
  });

  const { mutate } = useContactUs();

  function onSubmit(data: contactFormValues) {
    mutate(data);
    reset();
  }

  return (
    <div className="bg-contact-header-bg bg-no-repeat flex flex-col gap-11 md:gap-0">
      <div className="flex flex-col gap-3 md:gap-[30px] pt-[54px] md:pt-[90px] md:pb-[108px]">
        <AnimatedSection>
          <h1 className="heading-900 text-[34px] md:text-[64px] leading-[50px] md:leading-[72px] tracking-[0.01em] text-center text-[#1A1C1F]">
            <span className="text-[var(--primary)]">Get in</span> Touch
          </h1>
        </AnimatedSection>
        <AnimatedSection>
          <p className="md:text-xl text-base leading-[30px] text-center">
            Our friendly team is always here to chat.
          </p>
        </AnimatedSection>
      </div>
      <div className="bg-[#F8FCFF] pb-[96px]">
        <div className="wrapper flex flex-col gap-[32px] md:flex-row justify-between">
          <AnimatedSection>
            <div className="md:max-w-[384px]md:w-full text-center flex flex-col gap-5">
              <img src={Envelope} alt="" className="w-12 h-12 mx-auto" />
              <div className="flex flex-col gap-2">
                <p className="font-semibold text-xl leading-[30px] text-[#101828]">
                  Email
                </p>
                <p className="text-base leading-6">
                  Our friendly team is here to help.
                </p>
              </div>
              <p className="font-semibold text-base leading-6 text-[#2EC4B6]">
                hello@fluxtribe.co
              </p>
            </div>
          </AnimatedSection>
          <AnimatedSection>
            <div className="md:max-w-[384px]md:w-full text-center flex flex-col gap-5">
              <img src={LocationSvg} alt="" className="w-12 h-12 mx-auto" />
              <div className="flex flex-col gap-2">
                <p className="font-semibold text-xl leading-[30px] text-[#101828]">
                  Office
                </p>
                <p className="text-base leading-6">
                  Come say hello at our office HQ.
                </p>
              </div>

              <p className="font-semibold max-w-[202px] w-full mx-auto text-base leading-6 text-[#2EC4B6]">
                {/* 100 Smith Street Collingwood VIC 3066 AU */}
              </p>
            </div>
          </AnimatedSection>
          <AnimatedSection>
            <div className="md:max-w-[384px]md:w-full text-center flex flex-col gap-5">
              <img src={TelephoneSvg} alt="" className="w-12 h-12 mx-auto" />
              <div className="flex flex-col gap-2">
                <p className="font-semibold text-xl leading-[30px] text-[#101828]">
                  Phone
                </p>
                <p className="text-base leading-6">Mon-Fri from 8am to 5pm.</p>
              </div>

              <p className="font-semibold text-base leading-6 text-[#2EC4B6]">
                0802 068 9938
              </p>
            </div>
          </AnimatedSection>
        </div>
      </div>
      <div className="relative bg-white">
        <AnimatedSection fade>
          <div className="flex flex-col gap-6">
            <img src={WorkingPeople} alt="" />
            <div className="border p-6 max-w-[481px] md:max-w-[800px] w-full rounded-lg bg-white md:absolute md:top-[400px]  md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 z-[2] flex flex-col mx-auto md:mx-none gap-[64px]">
              <div className="flex flex-col gap-4">
                <h3 className="heading-700 text-[32px] leading-10 tracking-[0.02em] text-center text-[#111827]">
                  Send us a message
                </h3>
              </div>
              <form
                className="flex flex-col gap-8"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="flex flex-col gap-1">
                  <BaseInput
                    label="Name"
                    placeholder="Name"
                    {...register('name')}
                    type="text"
                    error={errors.name?.message}
                  />
                </div>
                <div className="flex flex-col gap-1 max-w-[752px] w-full">
                  <BaseInput
                    type="email"
                    label="Email Address"
                    {...register('email')}
                    data-testid="email"
                    error={errors.email?.message}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <Textarea
                    label="Message"
                    placeholder="Please provide a short description of your enquiries  and any questions you have about Fluxtribe and we’ll look into answering and solving them for you"
                    className="w-full"
                    rows={8}
                    {...register('message')}
                    error={errors?.message?.message}
                  />
                </div>
                <BaseButton className="max-w-[93px] h-10">Submit</BaseButton>
              </form>
            </div>
          </div>
        </AnimatedSection>

        <div className="w-full h-[80px] md:h-[450px]"></div>
      </div>
    </div>
  );
};
