import { type RouteObject } from 'react-router-dom';
import {
  CommunityEngagement,
  GoalSetting,
  Leaderboard,
  ContactUs,
  LandingPage,
  FAQPage,
  Privacy,
  Blogs,
  Terms,
} from '../features';

import { LandingPageLayout } from '../layouts';
import { BlogArticle } from '../components';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <LandingPageLayout />,
    children: [
      {
        path: '',
        element: <LandingPage />,
      },
      {
        path: 'contact-us',
        element: <ContactUs />,
      },
      {
        path: 'goal-settings',
        element: <GoalSetting />,
      },
      {
        path: 'community',
        element: <CommunityEngagement />,
      },
      {
        path: 'leaderboard',
        element: <Leaderboard />,
      },
      {
        path: 'faqs',
        element: <FAQPage />,
      },
      {
        path: 'blogs',
        children: [
          {
            path: '',
            element: <Blogs />,
          },
          {
            path: ':id',
            element: <BlogArticle />,
          },
        ],
      },
    ],
  },
  {
    path: '',
    element: <LandingPageLayout />,
    children: [
      {
        path: '/privacy-policy',
        element: <Privacy />,
      },
      {
        path: '/terms',
        element: <Terms />,
      },
    ],
  },
];
