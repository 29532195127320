import React from 'react';

import { BaseError } from '.';
import { cn } from '../libs';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  IconClick?: any;
  rightIcon?: any;
  isRequired?: any;
  error?: any;
  showClipboard?: boolean;
  value?: string;
  maximumLength?: number;
  showCounter?: boolean;
  setOpenInfo?: any;
  openInfo?: boolean;
  isInfo?: boolean;
}

export const BaseInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      IconClick,
      placeholder,
      id,
      type,
      rightIcon,
      isRequired,
      className,
      error,
      showClipboard,
      maximumLength,
      showCounter,
      setOpenInfo,
      openInfo,
      isInfo,
      ...rest
    },
    ref,
  ) => {
    const inputRef = React.useRef<HTMLDivElement>(null);

    function closeMenu(e: any) {
      if (
        inputRef.current &&
        openInfo &&
        !inputRef.current.contains(e.target)
      ) {
        setOpenInfo(false);
      }
    }

    document.addEventListener('mousedown', closeMenu);

    return (
      <div
        className={cn(`w-full grid gap-2`)}
        onClick={closeMenu}
        ref={inputRef}
      >
        {label ? (
          <label
            className={cn(
              `flex items-center text-sm font-medium leading-5 text-[#111827]`,
            )}
            htmlFor={id}
          >
            {label}
            {isRequired && <span className="text-error"> *</span>}
          </label>
        ) : null}
        <div
          className={cn(
            `grid grid-cols-[1fr_max-content] w-full border border-[#CDD3D3] rounded-lg h-12 items-center px-6 justify-between`,
            className,
          )}
        >
          <div className="w-full">
            <input
              id={id}
              data-testid={'input-text'}
              placeholder={placeholder}
              className={cn(
                'w-full bg-transparent text-base font-light disabled:cursor-not-allowed disabled:opacity-50 outline-none text-[#586667]',
              )}
              ref={ref}
              {...rest}
            />
          </div>
        </div>
        <BaseError error={error} />
      </div>
    );
  },
);

BaseInput.displayName = 'BaseInput';
