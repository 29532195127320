import { LandingPageHeading, LandingPageMain } from '../../components';

export function LandingPage() {
  return (
    <>
      <div className="flex flex-col gap-14 md:gap-[110px] bg-[#F8FCFF]">
        <LandingPageHeading />
        <LandingPageMain />
      </div>
    </>
  );
}
