import { GreenCircle, VideoImage } from '../../../../assets';
import { AnimatedSection } from '../../../../components';

export const HowItWorks = () => {
  return (
    <section className="relative wrapper">
      <AnimatedSection>
        <div
          className="pt-10 md:pt-[46px] px-8 md:px-[133px] pb-[253px] rounded-lg relative"
          style={{ background: 'rgba(133, 194, 228, 0.2)' }}
        >
          <div className="justify-center mx-auto flex flex-col gap-6 md:gap-12">
            <div className="text-center flex flex-col gap-3">
              <h1 className="heading-900 md:text-[48px] text-[30px] leading-[42px] md:leading-[56px] tracking-[0.01em] text-[#191B1F] w-full text-center flex justify-center items-center mx-auto  md:max-w-[487px]">
                Let's show you exactly how it works
              </h1>
              <p className="text-sm md:text-xl leading-[26px] tracking-[0.02em] lg:max-w-[533px] lg:w-full lg:mx-auto">
                Have fun. Experience the world of limitless achievements with
                your community.
              </p>
            </div>

            <div className="heading-700 text-sm md:text-[22.5px] leading-[32px] flex flex-col flex-wrap md:flex-row gap-4 md:justify-between">
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-5">
                  <img src={GreenCircle} alt="" />
                  <p className="text-[#000000]">Dream Big. Set Goals</p>
                </div>
                <div className="flex items-center gap-5">
                  <img src={GreenCircle} alt="" />
                  <p className="text-[#000000]">Winning Through Unity</p>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-5">
                  <img src={GreenCircle} alt="" />
                  <p className="text-[#000000]">
                    Conversations That Boost Personal Success
                  </p>
                </div>
                <div className="flex items-center gap-5">
                  <img src={GreenCircle} alt="" />
                  <p className="text-[#000000]">
                    Champion goals in global communities
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ background: 'rgba(255, 255, 255, 0.4)' }}
            className="absolute h-[451px] md:[483px] border-[#E1F0FA] max-w-[1050px] w-full border-2 rounded-[32px] md:py-[25px] p-[14px] md:px-[22px] left-1/2 transform -translate-x-1/2 md:mt-[34px] mt-[24px]"
          >
            <img
              src={VideoImage}
              alt=""
              className="w-full h-full md:block hidden rounded-3xl"
            />
            <img src={VideoImage} alt="" className="md:hidden w-full h-full" />
          </div>
        </div>
      </AnimatedSection>
    </section>
  );
};
