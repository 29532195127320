import React from 'react';

import classes from './Button.module.scss';

interface ButtonLoaderProps {
  color: string;
}

const ButtonLoader: React.FC<ButtonLoaderProps> = ({ color }) => {
  const loaderStyle: any = {
    '--loader-bg': color || 'var(--white)',
  };

  return (
    <div className={classes.ldsRing} style={loaderStyle}>
      <div className={classes.div}></div>
      <div className={classes.div}></div>
      <div className={classes.div}></div>
      <div className={classes.div}></div>
    </div>
  );
};

export {ButtonLoader};
