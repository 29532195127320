import { FeaturesComponent } from '../../../../components';
import {
  Leaderboard as LeaderboardImage,
  LeaderBoard2,
  LeaderBoard3,
  LeaderBoard1,
} from '../../../../assets';

const Leaderboard = () => {
  const feature = {
    heading:
      'Compete, Climb, and Celebrate\nSuccess with our Dynamic Leaderboard',
    coloredText: 'Dynamic Leaderboard',
    description: `Celebrate your triumph with a global leaderboard. Ascend to the peak of achievement as you pursue your goal - inspire and lead the world!`,
    button: 'Get Started',
    image: LeaderboardImage,
    gridSection: {
      heading: 'Let’s help you charge your\nleaderboard  with Fluxtribe app',
      coloredText: 'Fluxtribe',
      description:
        'Achieve your goals with healthy competition. Rise to the top, celebrate successes and inspire your world.',
      gridArray: [
        {
          heading: 'Join An Active Goal with Friends',
          description: 'Join a goal or create one to get started',
          button: 'Sign up',
          image: { src: LeaderBoard1, position: 'bottom' },
        },
        {
          heading: 'Submit and Complete Checkings for goals to earn points',
          description:
            'Soar to the top of the leaderboard and earn points with each successful goal review check-in you submit, by successfully referring new users and also reviewing other users check-ins that come your way!',
          button: 'Next',
          image: { src: LeaderBoard2, position: 'bottom' },
        },
        {
          heading: 'Invite Your Friends and Compete on the Leaderboard!',
          description:
            'Download FluxTribe app from Google play store and Apple store to create a new account and invite friends',
          button: 'Sign up',
          image: { src: LeaderBoard3 },
        },
      ],
    },
    joinSection: {
      heading: 'How to join leaderboard on fluxtribe',
      description:
        'Find a community that is aiming at the same goal as you and join',
      button: 'Join a goal',
      isStep: true,
      steps: [
        {
          name: 'Go to goal menu',
          description: `Click on ''Goal menu'' at the bottom navigation bar and select ''Goal'' Search for the type of goal you are interested in to join`,
          coloredText: `''Goal menu''`,
        },
        {
          name: 'Start a goal or join a community',
          description: `Select the goal you want to join and carefully read information about it. Then click on the ''join'' button. You can now join the conversation in the goal chat channel.
          
          
          Note:

          You can not opt out of a goal until the goal is completed or the end date.
          `,
          coloredText: `''join''`,
        },
      ],
    },
  };

  return <FeaturesComponent feature={feature} />;
};

export { Leaderboard };
