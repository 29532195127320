import React from 'react'
import { AndroidPhone, AppStore, GooglePlay, Together } from '../../../assets'
import { AnimatedSection } from '../../../components'


const FAQPage = () => {
  return (
    <div className='max-w-[1240px] m-auto grid gap-9'>
      <div className='grid lg:grid-cols-[1fr_400px] gap-[60px] items-center bg-[#FEF9F0] p-[51px_24px] lg:p-[51px_26px_49px_47px]'>
        <AnimatedSection>
          <p className='font-satoshi text-[#191B1F] font-black text-3xl lg:text-5xl leading-[56.7px]'><span className='text-[#FFCA3A]'>Unleash Achievements</span>, Foster Connections, and Transform Aspirations into Reality.</p>
        </AnimatedSection>
        <AnimatedSection>
          <img className='w-full' src={Together} alt='' />
        </AnimatedSection>
      </div>
      <hr className='bg-[#E4EAEF]' />
      <div className='bg-[#EBF6FE] flex flex-col-reverse gap-[68px] lg:gap-0 lg:grid lg:grid-cols-[1fr_455px] items-center p-[46px_24px_0] lg:p-[46px_34px_0_42px]'>
       <AnimatedSection>
        <img src={AndroidPhone} alt='' />
       </AnimatedSection>   
        <AnimatedSection>
          <div className=''>
            <h3 className='font-bold text-[#171717] text-3xl lg:text-4xl leading-[48px]'><span className='text-[#0097FF]'>Fluxtribe on the go:</span> achieve anywhere</h3>
            <p className='text-sm lg:text-base text-[var(--dark)] mt-3'>Download the Fluxtribe app and turn every moment into a step toward success. Set goals, chat, and win prizes effortlessly.</p>
            <div className='grid gap-5 grid-cols-[156px_138px] mt-8'>
              <button title='button-1' type='button'>
                <img src={GooglePlay} alt='' />
                </button>

              <button title='button-2' type='button'>
                <img src={AppStore} alt='' />
              </button>
            </div>
          </div>
        </AnimatedSection>       
      </div>
      <hr className='bg-[#E4EAEF] mb-[120px]' />
    </div>
  )
}

export {FAQPage}