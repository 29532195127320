import { useQuery } from '@tanstack/react-query';
import StoryblokClient from 'storyblok-js-client';

export const useBlogsStoryBlokData = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['storyblokData'],
    queryFn: async () => {
      const Storyblok = new StoryblokClient({
        accessToken: 'g7mrZRG71tTRNGjWjgJJ3wtt',
      });

      const response = await Storyblok.get('cdn/stories', {
        version: 'draft',
        starts_with: 'blogs/',
      });
      const stories = response.data.stories.map(
        (story: { content: any }) => story.content,
      );

      return stories;
    },
  });
  return { data, isLoading };
};
