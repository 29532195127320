import { Link } from 'react-router-dom';
import { Devices } from '../../../../assets';
import { BaseButton } from '../../../../components';

export const SeamlessSuccess = () => {
  return (
    <div className="bg-[#ECF7F6] pt-[33px] md:pt-[64px] pl-[10px] md:pl-[42px] flex flex-col gap-[67px] md:flex-row justify-between md:items-center">
      <div className="flex flex-col gap-6 md:max-w-[449px] w-full">
        <div className="flex flex-col gap-3">
          <p className="heading-700 text-4xl leading-[48.6px] text-[#171717] max-w-[417px] w-full">
            <span className="text-[var(--primary)]">Seamless success:</span>{' '}
            Fluxtribe web app
          </p>

          <p className="text-base leading-5 tracking-[0.02em]">
            Explore the Fluxtribe web app to start your journey. Create goals,
            connect with the community, and realise dreams.
          </p>
        </div>
        <Link to="https://merifot.enyata.com/auth/login" target="_blank">
          <BaseButton className="max-w-[173px] w-full h-11">
            Let's Get Started
          </BaseButton>
        </Link>
      </div>
      <div>
        <img src={Devices} alt="" className="w-full h-full" />
      </div>
    </div>
  );
};
