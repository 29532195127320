import { Button } from '../Button';
import { Android, Apple, Logo } from '../../assets';
import { NavMenu } from '../NavBar';
import { NavLink, useNavigate } from 'react-router-dom';

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-[var(--black)] mt-[60px] ">
      <div className="navbar max-w-[1240px] m-auto py-[76px] px-5">
        <div className="flex justify-between  pb-7 border-b border-[var(--gray-500)]">
          <NavMenu />
          <div className="flex gap-3">
            <Button variant="link" onClick={() => navigate('/privacy-policy')}>
              Privacy
            </Button>{' '}
            <Button variant="link" onClick={() => navigate('/terms')}>
              Terms of Use
            </Button>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-4">
            <p className="text-white mt-6 font-satoshi">
              Available on web and also on
            </p>
            <div className="flex gap-6">
              <Button variant="image">
                <div className="flex items-center gap-3">
                  <img src={Android} alt="" />
                  <p>Playstore</p>
                </div>
              </Button>
              <Button variant="image">
                <div className="flex items-center gap-3">
                  <img src={Apple} alt="" />
                  <p>Applestore</p>
                </div>
              </Button>
            </div>
          </div>

          <p className="text-[#81878E]">©CoralQuest Limited - RC7017099. </p>
        </div>
      </div>
      <div className="navbar-menu m-[0_24px] p-[40px_0_28px] grid gap-11">
        <div className="grid gap-9">
          <NavLink to="">
            <img src={Logo} alt="" />
          </NavLink>
          <div className="flex flex-col gap-4">
            <p className="text-white mt-6 font-satoshi">
              Available on web and also on
            </p>
            <div className="flex gap-6">
              <Button variant="image">
                <div className="flex items-center gap-3">
                  <img src={Android} alt="" />
                  <p>Playstore</p>
                </div>
              </Button>
              <Button variant="image">
                <div className="flex items-center gap-3">
                  <img src={Apple} alt="" />
                  <p>Appstore</p>
                </div>
              </Button>
            </div>
          </div>
          <div className="mt-5">
            <NavMenu mobile />
          </div>
        </div>
        <hr className="border-[#565E62] my-10" />
        <div className="grid gap-[56px] justify-items-center">
          <div className="flex gap-3">
            <Button variant="link">Privacy</Button>
            <Button variant="link">Terms of Use</Button>
          </div>
          <p className="text-[#81878E]">©CoralQuest Limited - RC7017099. </p>
        </div>
      </div>
    </div>
  );
};
