import Goals from './goals.png';
import Community from './community.png';
import Leaderboard from './leaderboard.jpg';
import BlueBanner from './blue-banner.png';
import ManOnPhone from './man-on-phone.png';
import TealBanner from './teal-banner.png';
import WomanSmiling from './woman-smiling.png';
import Collage from './collage.png';
import Goals1 from './goals-1.png';
import Goals2 from './goals-2.png';
import Goals3 from './goals-3.png';
import Goals4 from './goals-4.png';
import Goals5 from './goals-5.png';
import Goals6 from './goals-6.png';
import Blog from './blog.jpg';
import Blog1 from './blog-1.jpg';
import Blog2 from './blog-2.jpg';
import Blog3 from './blog-3.jpg';
import Blog3_1 from './blog-3.jpg';
import GeometricBackground from './geometric-background-bright.png';
import GeoBg from './geometric-background.jpg';
import DashboardScreens from './dashboard-screens.png';
import GroupsOfPeople from './groups-of-people.jpg';
import HoldingAPhone from './holding-a-phone.jpg';
import Hugging from './hugging.jpg';
import PeopleInGlasses from './people-in-sunglasses.jpg';
import GoalsGirls from './goal.jpg';
import TravelLovers from './travel-lovers.jpg';
import StakeAndWin from './stake-and-win.jpg';
import Hold from './hold.png';
import VideoImage from './video-image.png';
import Together from './together.png';
import Devices from './devices.png';
import AppStore from './app-store.png';
import GooglePlay from './google-play.png';
import Profile from './profile.png';
import SharingCharts from './sharing-charts.png';
import HuddleTogether from './huddle-together.png';
import GetStartedImage from './get-started.png';
import GetStartedImage2 from './get-started-2.png';
import ContactBackgroundHeader from './contact-background-header.png';
import WorkingPeople from './working-people.png';
import AndroidPhone from './android-phone.png';
import LeaderBoard1 from './leader-1.png';
import LeaderBoard2 from './leader-2.png';
import LeaderBoard3 from './leader-3.png';
import Community1 from './community-1.png';
import Community2 from './community-2.png';
import Community3 from './community-3.png';
import Community4 from './community-4.png';

export {
  Community1,
  Blog,
  Blog1,
  Blog2,
  Blog3,
  Blog3_1,
  Community2,
  Community3,
  Community4,
  LeaderBoard1,
  LeaderBoard2,
  LeaderBoard3,
  Goals,
  Community,
  Leaderboard,
  BlueBanner,
  ManOnPhone,
  TealBanner,
  WomanSmiling,
  Collage,
  Goals1,
  Goals2,
  Goals3,
  Goals4,
  Goals5,
  Goals6,
  GeometricBackground,
  ContactBackgroundHeader,
  AndroidPhone,
  GeoBg,
  DashboardScreens,
  GroupsOfPeople,
  HoldingAPhone,
  Hugging,
  PeopleInGlasses,
  GoalsGirls,
  TravelLovers,
  StakeAndWin,
  Hold,
  VideoImage,
  Together,
  Devices,
  AppStore,
  GooglePlay,
  Profile,
  SharingCharts,
  HuddleTogether,
  GetStartedImage,
  GetStartedImage2,
  WorkingPeople,
};
