import React from 'react';

import classes from './BaseButton.module.scss';
import ButtonLoader from './ButtonLoader';
import { cn } from '../../libs';
type Props = Readonly<{
  children: React.ReactNode;
  variant?: string;
  styles?: object;
  size?: string;
  className?: string;
  image?: any;
  isLoading?: boolean;
  type?: any;
  color?: string;
  onClick?: any;
  disabled?: boolean;
}>;
const BaseButton = ({
  children,
  variant = 'primary',
  styles,
  className,
  isLoading,
  ...rest
}: Props) => {
  return (
    <button
      className={cn([
        className,
        classes.btn,
        classes[variant],
        { [classes.loading]: isLoading },
      ])}
      style={{ ...styles }}
      {...rest}
    >
      {variant === 'primary' ? <div className={classes.green}></div> : null}
      {isLoading ? <ButtonLoader color={''} /> : <>{children}</>}
    </button>
  );
};
export { BaseButton };
