import { BaseButton } from '../../../../components';
import { HuddleTogether, Profile, SharingCharts } from '../../../../assets';

export const BlogSection = () => {
  return (
    <div className="flex flex-col gap-[30px]">
      <div className="flex flex-col md:flex-row gap-6 justify-between md:items-center">
        <h1 className="heading-700 text-5xl leading-[72px] tracking-[-2.3px] text-[#191B1F]">
          Check out our blogs
        </h1>
        <BaseButton
          variant="tertiary"
          className="h-[46px] max-w-[163px] w-full"
        >
          See more Blogs
        </BaseButton>
      </div>
      <div className="flex flex-col lg:flex-row  rounded-xl bg-[#13544E]">
        <div>
          <img
            src={SharingCharts}
            alt=""
            className="hidden md:block lg:max-w-[748px] w-full h-full rounded-xl lg:rounded-none"
          />
          <img src={HuddleTogether} alt="" className="md:hidden" />
        </div>
        <div className="py-6 pl-[30px] pr-10 flex flex-col">
          <div className="flex flex-col justify-between gap-11">
            <div className="flex flex-col gap-1">
              <p className="heading-700 text-[#B0EBE6] text-sm leading-[27px] tracking-[0.05em]">
                Company
              </p>
              <div className="md:max-w-[346px] w-full flex flex-col gap-3">
                <h4 className="heading-700 text-2xl leading-9 -tracking-[0.03em] text-white">
                  Embarking on the Path to Success: Unveiling the Stories,
                  Strategies, and Community Triumphs of Fluxtribe.{' '}
                </h4>
                <p className="text-base leading-6 tracking-[0.01em] text-[#CED8D7]">
                  Discover the inspiring stories, practical tips, and community
                  triumphs that define Fluxtribe’s journey to success. Join us
                  on a quest to unlock your full potential and turn aspirations
                  into achievements.{' '}
                </p>
              </div>
            </div>

            <div className="flex justify-between heading-500 text-base leading-6 tracking-[0.01em]">
              <div className="flex items-center gap-1">
                <div>
                  <img src={Profile} alt="" className="max-w-8 w-full h-full" />
                </div>
                <p className="text-[#CED8D7]">Ethan Mitchell</p>
              </div>
              <p className="text-[#CED8D7]">14 Dec, 2023</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
