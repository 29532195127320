import { MutatingDots } from 'react-loader-spinner';

export const Loader = () => (
  <MutatingDots
    visible={true}
    height="100"
    width="100"
    color="#2EC4B6"
    secondaryColor="#2EC4B6]"
    radius="12.5"
    ariaLabel="mutating-dots-loading"
    wrapperStyle={{}}
    wrapperClass=""
  />
);
