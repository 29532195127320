import { Together } from '../../../../assets';
import { AnimatedSection } from '../../../../components';
import { BlogSection } from '../BlogSection';

import { FluxTribeOnTheGo } from '../FluxTribeOnTheGo';
import { SeamlessSuccess } from '../SeamlessSucess';

export const UnleashAchievements = () => {
  return (
    <div className="flex flex-col gap-[140px] main_wrapper">
      <AnimatedSection>
        <div className="md:mt-[300px] mt-[210px] flex flex-col gap-9">
          <div className="py-[38px] md:pb-0 md:pt-4 md:pr-[26px] pl-[10px] md:pl-[47px] text-left flex flex-col lg:flex-row gap-[64px] md:justify-between items-center bg-[#FEF9F0]">
            <p className="heading-900 md:max-w-[694px] w-full text-[#191B1F] text-[29px] md:text-[42px] leading-[40.5px]">
              <span className="text-[#FFCA3A]">Unleash Achievements</span>,
              Foster Connections, and Transform Aspirations into Reality.
            </p>
            <div>
              <img src={Together} alt="" />
            </div>
          </div>
          <hr className="bg-[#E4EAEF]" />
          <SeamlessSuccess />
          <FluxTribeOnTheGo />
          <hr className="bg-[#E4EAEF]" />
        </div>
      </AnimatedSection>

      <AnimatedSection>
        <BlogSection />
      </AnimatedSection>
    </div>
  );
};
