import React from 'react';

import { cn } from '../../libs';
import { BaseError } from '../BaseErrorText';

interface CustomTextareaProps {
  error?: string;
  label?: string;
  className?: string;
  isRequired?: boolean;
}

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  CustomTextareaProps;

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ error, className, isRequired, id, label, rows = 5, ...rest }, ref) => {
    return (
      <div className={cn('grid gap-2')}>
        <label
          htmlFor={id}
          className="text-sm text-[var(--black-100)] leading-[150%] tracking-[0.14px] font-medium"
        >
          {label}
          {isRequired && <span className="text-error"> *</span>}
        </label>
        <textarea
          id={id}
          rows={rows}
          className={cn(
            'w-full border border-gray-200 rounded-lg px-6 py-4 text-black-100 outline-none text-[#586667]',
            className,
          )}
          ref={ref}
          {...rest}
        />
        <div className="mt-1">
          <BaseError error={error} />
        </div>
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';
