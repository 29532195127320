import {
  DashboardScreens,
  Diamond,
  Globe,
  GroupsOfPeople,
  HoldingAPhone,
  Hugging,
  Lightbulb,
} from '../../../assets';
import { cn } from '../../../libs';
import { AnimatedSection, BaseButton, Input } from '../../../components';

import React from 'react';
import { useEmail } from '../../../hooks';

export const LandingPageHeading = () => {
  const [email, setEmail] = React.useState('');
  const { isPending, mutate } = useEmail();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutate(email);
  };

  return (
    <header className="relative">
      <div className="relative flex flex-col md:gap-[53px] z-10 justify-center items-center text-center pt-[89px] h-full w-full bg-hero-pattern bg-no-repeat">
        <div className="flex flex-col md:gap-[61px] gap-[92px]">
          <div className="flex flex-col md:gap-[30px] gap-[36px]">
            <div className="flex flex-col text-center items-center justify-center md:gap-[30px] gap-6">
              <div className="heading-900 leading-9 md:leading-[72px] tracking-[-1px] md:text-[64px] text-[34px]">
                <h1 className="text-[var(--primary)] text-center">
                  <AnimatedSection>
                    Find. Connect & Achieve <br />{' '}
                    <span className="text-[#1A1C1F]">
                      your goal as a community
                    </span>
                  </AnimatedSection>
                </h1>
              </div>
              <AnimatedSection>
                <p className="md:text-xl md:leading-10 text-[14px] tracking-[2%] leading-5 text-center">
                  We make committing to your goals seamless and highly rewarding
                </p>
              </AnimatedSection>
            </div>

            <form
              className={cn(
                'flex gap-1 items-center p-1 rounded-xl border border-[#D1D5DB] max-w-[384px] md:max-w-[550px] mx-auto w-full',
                { 'border-red-500': '' },
              )}
              onSubmit={handleSubmit}
            >
              <Input
                value={email}
                placeholder="Enter your email address"
                type="email"
                className={cn(
                  'bg-transparent pl-4 font-medium placeholder:text-[#9CA3AF] appearance-none',
                  {
                    'placeholder:text-[#9CA3AF]': '',
                  },
                )}
                onChange={(e) => setEmail(e.target.value)}
              />
              <BaseButton
                isLoading={isPending}
                className={cn(
                  'md:max-w-[173px] w-full md:h-[60px] h-[46px] max-w-[124px] max-sm:rounded-lg font-bold leading-5 tracking-[-0.31px]',
                )}
              >
                Get Started
              </BaseButton>
            </form>
          </div>

          <div className="relative w-full">
            <div className="max-w-[1190px] w-full flex justify-center items-center mx-auto">
              <img src={DashboardScreens} alt="" className="w-full h-full" />
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row max-w-[1440px] w-full">
          <div className="relative w-full flex justify-center items-center">
            <img src={HoldingAPhone} alt="" className="w-full" />
            <div className="absolute inset-0 bg-[#1D3461] opacity-40" />
            <div className="max-w-[345px] w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-3">
              <img src={Globe} alt="" className="mx-auto" />
              <p className="heading-900 text-[28px] leading-[37.8px] text-white">
                No. 1 Global <br />
                Community Hub
              </p>
            </div>
          </div>
          <div className="relative w-full  flex justify-center items-center">
            <img src={Hugging} alt="" className="w-full" />
            <div className="absolute inset-0 bg-[#FFCA3A] opacity-60" />
            <div className="max-w-[345px] w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-3">
              <img src={Diamond} alt="" className="mx-auto" />
              <p className="heading-900 text-[28px] leading-[37.8px] text-white">
                Someone Wins Prizes Daily on Fluxtribe
              </p>
            </div>
          </div>
          <div className="relative w-full  flex justify-center items-center">
            <img src={GroupsOfPeople} alt="" className="w-full" />
            <div className="absolute inset-0 bg-[#2EC4B6] opacity-30" />
            <div className="max-w-[345px] w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-3">
              <img src={Lightbulb} alt="" className="mx-auto" />
              <p className="heading-900 text-[28px] leading-[37.8px] text-white">
                Dive into the Premier Goal-Setting Experience
              </p>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
