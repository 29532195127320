import { FeaturesComponent } from '../../../../components';
import {
  Community,
  Community1,
  Community2,
  Community3,
  Community4,
} from '../../../../assets';

const CommunityEngagement = () => {
  const feature = {
    heading: 'Forge Connections, Share\nAchievements, and Thrive Together',
    coloredText: 'Forge Connections',
    description: `Set goals, have fun, and fast-track your achievements together with your tribe. It's that easy.`,
    button: 'Get Started',
    image: Community,
    gridSection: {
      heading:
        'Let us help you create an inspiring\ncommunity with FluxTribe app',
      coloredText: 'Fluxtribe',
      description:
        'Step into a world of exciting goal-setting and limitless achievements. Unleash the power of community.',
      gridArray: [
        {
          heading: 'Set Permission levels on who can join a community',
          description:
            'Invite only those you want in the community by choosing the private community type or allow anyone join through the public community type.',
          button: 'Next',
          image: { src: Community1, position: 'bottom' },
        },
        {
          heading: 'Flexibility to join communities that interest you',
          description:
            'Attract the right members to your community by selecting interests that align with the community goals.',
          button: 'Next',
          image: { src: Community2, position: 'none' },
        },
        {
          heading: 'Easily create a community with like-minded people',
          description:
            'Write a simple description of your community, define community expectations and set the rules.',
          button: 'Next',
          image: { src: Community3, position: 'bottom' },
        },
        {
          heading: 'Invite Your Friends and Compete on the Leaderboard!',
          description:
            'The goal is set. Allow others join your community by sending invites or share goal with the public.',
          button: 'Next',
          image: { src: Community4, position: 'bottom' },
        },
      ],
    },
    joinSection: {
      heading: 'How to join community on fluxtribe',
      description:
        'Find a community that is aiming at the same goal as you and join',
      button: 'Join a community',
      steps: [
        {
          name: 'Go to community menu',
          description: `Click on ''Goal menu'' at the bottom navigation bar and select ''Goal'' Search for the type of goal you are interested in to join`,
          coloredText: `''Goal menu''`,
        },
        {
          name: 'Join community',
          description: `From a the list of available communities, select one that aligns with your goal. Carefully read information about community. Then, click on the ''join'' button. You can now join activities in the community.
          
          
          Note:
          You can not opt out of a goal until the goal is completed or the end date.
          `,
          coloredText: `''join''`,
        },
      ],
    },
  };

  return <FeaturesComponent feature={feature} />;
};

export { CommunityEngagement };
