import { AccordionDataItems } from '../../libs';

export const accordionData: AccordionDataItems[] = [
  {
    id: 1,
    question: 'Who should join FluxTribe?',
    content:
      'KreditAfrica will analyse your documents and give insight to your creditor to make an informed decision.',
  },
  {
    id: 2,
    question: 'Can I create a community goal on FluxTribe?',
    content: 'From the first quarter of 2024.',
  },
  {
    id: 3,
    question: 'Is FluxTribe app free?',
    content: `The Fluxtribe App is completely free. You can choose to stake on your goal or not.`,
  },
  {
    id: 4,
    question: 'Can I track individual goal progress on FluxTribe?',
    content: `We offer a range of services ranging from document verification, credit inquiry, customers' bank behaviour, fraud insight, risk assessment etc. Some of which will be available from the first quarter of 2024.`,
  },
  {
    id: 5,
    question: 'How many goals can I create at a time?',
    content: `KreditAfrica's services aim to increase the accuracy of credit assessments, which may lead to more informed lending decisions and potentially improved loan approval rates for individuals.`,
  },
  {
    id: 6,
    question: 'Who we are?',
    content:
      'You can create as many goals as you desire to achieve. You are limitless.',
  },
  {
    id: 7,
    question: 'How do we ensure security on our platform?',
    content:
      'You can create as many goals as you desire to achieve. You are limitless.',
  },
];
