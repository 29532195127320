import axios from 'axios';
import { useToastify } from './useToastify';
import { useMutation } from '@tanstack/react-query';

type TContactUs = {
  name: string;
  message: string;
  email: string;
};

export function useContactUs() {
  const { errorToast, successToast } = useToastify();

  const { mutate, isPending } = useMutation({
    mutationFn: (constactUS: TContactUs) => {
      const { name, email, message } = constactUS;
      return axios.post('https://merifot-api.enyata.com/api/v1/contact/send', {
        name,
        email,
        message,
      }) as unknown as Promise<{
        data: any;
        message: string;
      }>;
    },
    onSuccess: async (data) => {
      successToast(data.data.message);
    },
    onError: async (data: {
      response: { data: { message: string }; status: string };
    }) => {
      errorToast(data?.response?.data?.message);
    },
  });

  return { isPending, mutate };
}
