import { Button } from '../Button';
import { GeoBg, GreenCircle } from '../../assets';
import { StyleText } from './StyleText';
import { AnimatedSection } from '../AnimateSection';
import { Link } from 'react-router-dom';

const FeaturesComponent = ({ feature }) => {
  const checklist = [
    'Dream Big. Chat Bold. Win Bigger',
    'Win Through Unity',
    'Conversations that Catalyze Success',
    'Champion Goals, Chat Globally, Win Prizes',
  ];

  return (
    <div className="relative md:auto mx-6">
      <img className="absolute w-full top-0 left-0 z-0" src={GeoBg} alt="" />
      <div className="relative max-w-[1240px] m-[auto] pt-[56px] md:pt-[90px] z-10">
        <div className="">
          <AnimatedSection>
            <div className={`grid gap-6`}>
              <h1 className="text-[#1A1C1F] font-black md:text-[64px] text-4xl whitespace-pre-line leading-[43px] md:leading-[73px]">
                <StyleText
                  text={feature.heading}
                  coloredText={feature.coloredText}
                />
              </h1>
              <p className="text-[#534E50] mt max-w-[907px] text:sm md:text-xl leading-[20px]  md:leading-[40px]">
                {feature.description}
              </p>

              <Link to="https://merifot.enyata.com/auth/login" target="_blank">
                <Button>{feature.button}</Button>
              </Link>
            </div>
          </AnimatedSection>
          <div className="relative m-auto">
            <img
              className="mt-[49px] md:mt-[68px] object-cover p-3 md:p-[22px_24px] border border-[#E1F0FA] rounded-[32px] h-[570px]"
              src={feature.image}
              alt=""
            />
            <ul className="grid gap-4 d absolute md:bottom-[66px] bottom-[60%] left-9 max-w-[312px] md:max-w-full md:left-[71px] ">
              {checklist.map((check) => (
                <AnimatedSection>
                  <li className="flex gap-5 text-sm md:text-2xl items-center cursor-default text-white font-satoshi font-bold">
                    <img src={GreenCircle} alt="" />
                    {check}
                  </li>
                </AnimatedSection>
              ))}
            </ul>
          </div>
        </div>
        <div className="my-[60px] md:my-[120px]">
          <AnimatedSection>
            <div className="m-auto text-center">
              <h2 className="font-black text-[#1A1C1F] whitespace-pre-line text-3xl md:text-5xl">
                <StyleText
                  text={feature.gridSection?.heading}
                  coloredText={feature.gridSection?.coloredText}
                />
              </h2>
              <p className="text-[var(--dark)] text-sm md:text-xl leading-7 tracking-wide mt-3">
                {feature.gridSection.description}{' '}
              </p>
            </div>
          </AnimatedSection>
          <div className={`grid md:grid-cols-2 gap-[51px_40px] mt-[95px]`}>
            {feature?.gridSection?.gridArray?.map((gridText, index) => (
              <div
                className={`overflow-hidden grid gap-[70px] md:gap-5 border bg-white border-dashed border-[#ADCDD8] rounded-xl items-center ${feature?.gridSection?.gridArray.length % 2 && feature?.gridSection?.gridArray.length - 1 === index ? 'last-item lg:col-span-2 lg:grid-cols-[1fr_420px] gap-[64px] p-[47px_0_28px] md:p-0' : 'grid-items lg:grid-cols-[270px_1fr] pl-8 pr-3 pt-[47px] lg:pt-0 h-[635px] lg:h-[379px]'}`}
              >
                <AnimatedSection>
                  <h2 className="font-jost font-semibold text-xl text-[#1E1C1D]">
                    {gridText.heading}
                  </h2>
                  <p className="text-[#534E50] m-[10px_0_24px] text-base">
                    {!gridText.coloredText ? (
                      gridText.description
                    ) : (
                      <StyleText
                        isBold
                        text={gridText.description}
                        coloredText={gridText.coloredText}
                      />
                    )}
                  </p>
                  <Link
                    to="https://merifot.enyata.com/auth/login"
                    target="_blank"
                  >
                    <Button variant="outline">{gridText.button}</Button>
                  </Link>
                </AnimatedSection>
                <img
                  className={`${gridText?.image?.position}`}
                  alt=""
                  src={gridText.image.src}
                />
              </div>
            ))}
          </div>
          <div className="grid lg:flex gap-11 justify-between mt-11 md:mt-[51px]">
            <AnimatedSection>
              <div className="text-[#534E50] flex flex-col gap-5">
                <h3 className="w-full md:w-[460px] md:text-[42px] md:leading-[3.5rem] text-3xl font-bold">
                  {feature.joinSection.heading}
                </h3>
                <p className="md:w-[425px] md:text-xl text-sm leading-10 tracking-wide mt-5">
                  {feature.joinSection.description}
                </p>
                <Link
                  to="https://merifot.enyata.com/auth/login"
                  target="_blank"
                >
                  <Button>{feature.joinSection.button}</Button>
                </Link>
              </div>
            </AnimatedSection>
            <div className="border bg-white border-dashed border-[#ADCDD8] p-[40px_32px] rounded-xl ">
              <AnimatedSection>
                <div className="md:w-[450px] grid">
                  {feature.joinSection.steps.map((step, index) => (
                    <>
                      <div className="max-w-[406px]">
                        {feature.joinSection.isStep ? (
                          <h3 className="flex gap-2 items-center font-abril text-[#1D3461]">
                            <span className="bg-[#E5F1FF] p-3 rounded-[50%]">
                              0{index + 1}
                            </span>{' '}
                            Step 0{index + 1}
                          </h3>
                        ) : (
                          ''
                        )}
                        <h4 className="mt-4 font-semibold text-lg text-[#1E1C1D]">
                          {step.name}
                        </h4>
                        <p className="mt-2 text-[#534E50] leading-6 tracking-wide whitespace-pre-line">
                          <StyleText
                            text={step.description}
                            coloredText={step.coloredText}
                            isBold
                          />{' '}
                        </p>
                      </div>
                      {index === feature.joinSection.steps.length - 1 ? null : (
                        <hr className="w-full h my-6 border-[#C3DEFF]" />
                      )}
                    </>
                  ))}
                </div>
              </AnimatedSection>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { FeaturesComponent };
