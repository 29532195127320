import { Banner, Footer, NavBar, FAQs } from "../components";
import { Outlet, useLocation } from 'react-router';
import { useEffect } from 'react';

export const LandingPageLayout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Scroll to top on route change
    scrollToTop();

    // Ensure scroll to top after DOM update
    const timeoutId = setTimeout(scrollToTop, 100);

    return () => clearTimeout(timeoutId);
  }, [pathname]);

return (
  <>
    <div>
      <NavBar />
      {!pathname?.includes('faqs') ? (
        <>
          <Outlet />
          <FAQs />
        </>
      ) : (
        <>
          <FAQs noImage />
          <Outlet />
        </>
      )}
      <Banner />
      <Footer />
    </div>
  </>
);
};
function useLayoutEffect(arg0: () => void, arg1: never[]) {
  throw new Error('Function not implemented.');
}

