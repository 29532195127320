import axios from 'axios';
import { useToastify } from './useToastify';
import { useMutation } from '@tanstack/react-query';

export function useEmail() {
  const { errorToast, successToast } = useToastify();

  const { mutate, isPending } = useMutation({
    mutationFn: (email: string) => {
      return axios.post('https://merifot-api.enyata.com/api/v1/email/send', {
        email,
      }) as unknown as Promise<{
        data: any;
        message: string;
      }>;
    },
    onSuccess: async (data) => {
      successToast(data?.data);
    },
    onError: async (data: {
      response: { data: { message: string }; status: string };
    }) => {
      errorToast(data?.response?.data?.message);
    },
  });

  return { isPending, mutate };
}
