import React from 'react';
import { useNavigate } from 'react-router';
import { TermsData } from '../../../mocks/Terms';

export const Terms = () => {
  const navigate = useNavigate();
  return (
    <div className="wrapper py-[25px]">
      <div
        className="rounded-2xl py-9 px-8 bg-[#FFFFFF] border-opacity-100 flex flex-col gap-[30px]"
        style={{ boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.13)' }}
      >
        <div className="pt-[41px] pb-[48px]">
          <p className="text-[#6B6F76] font-medium text-base leading-6">
            Version 1  · Last updated: April 5, 2021
          </p>
          <p className="text-[#27282B] font-bold text-[37.81px] leading-[50px] tracking-[-1px]">
            CoralQuest Website Terms and Conditions
          </p>
        </div>
        <div className="flex flex-col gap-[30px]">
          {TermsData.map((section, index) => {
            return (
              <div key={index} className="flex flex-col gap-[30px]">
                <p className="text-[#27282B] font-semibold text-[21.48px] leading-[27.5px] tracking-[-0.55px]">
                  {section.heading}
                </p>
                <div className="flex flex-col gap-[30px]">
                  {section.contents.map((content, contentIndex) => {
                    return (
                      <p
                        key={contentIndex}
                        className="text-base leading-[26px] text-[#27282B]"
                      >
                        {content}
                      </p>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col gap-4 py-16">
        <div className="flex gap-2 font-medium text-sm leading-5 justify-center">
          <button className="text-[#DB4B26]" onClick={() => navigate('/terms')}>
            Terms
          </button>
          <button
            className="text-[#DB4B26]"
            onClick={() => navigate('/privacy-policy')}
          >
            Privacy Policy
          </button>
          <p className="text-[#000000]">Follow us on Twitter</p>
        </div>
      </div>
    </div>
  );
};
