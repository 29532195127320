import React from 'react';
import { AnimatedSection } from '../AnimateSection';
import { Button } from '../Button';
import { ArrowDownWhite, ArrowRight } from '../../assets';
import { useNavigate } from 'react-router';
import { useBlogsStoryBlokData } from '../../hooks/storyblok/useBlogsStoryBlokData';
import { formatDate } from '../../utils';

export const BlogsComponent = ({
  blogs,
  activeTab,
}: {
  blogs: {
    topArticle: {
      img: string;
      details: {
        category: string;
        heading: string;
        description: string;
        author: string;
        date: string;
        authorImage: string;
      };
    };
    mostRead: {
      id: number;
      image: string;
      description: string;
      author: string;
      date: string;
      heading: string;
      facebookUrl: string;
      linkedinUrl: string;
      authorImage: string;
    }[];
    recentlyAdded: {
      id: number;
      image: string;
      description: string;
      author: string;
      date: string;
      heading: string;
      authorImage: string;
      facebookUrl: string;
      linkedinUrl: string;
    }[];
  };
  activeTab?: {
    name: string;
    value: string;
  };
}) => {
  const { data: storyblok } = useBlogsStoryBlokData();

  const topArticle = storyblok?.filter(
    (blog: { topArticle: boolean }) => blog.topArticle,
  )[0];

  return (
    <>
      <div className="grid gap-[56px]">
        <div className="grid md:grid-cols-[784px_1fr] bg-[#13544E] rounded-xl">
          <img
            className="h-full object-cover rounded-xl"
            src={topArticle?.image.filename}
            alt=""
          />
          <div className="grid gap-[96px] p-[24px_40px_24px_30px]">
            <div className="">
              <AnimatedSection>
                <h3 className="uppercase text-sm text-[#B0EBE6] font-bold">
                  {topArticle?.category}
                </h3>
              </AnimatedSection>
              <AnimatedSection>
                <p className="font-jost text-base mt-2 mb-3 text-[#CED8D7]">
                  {topArticle?.heading}
                </p>
              </AnimatedSection>
              <AnimatedSection>
                <h4 className="text-2xl text-white font-bold">
                  {topArticle?.description?.content[0]?.content[0]?.text}
                </h4>
              </AnimatedSection>
            </div>
            <div className="flex justify-between items-center">
              <AnimatedSection>
                <div className="flex items-center gap-2">
                  <img
                    className="w-8 h-8 rounded-[50%] font-satoshi"
                    src={topArticle?.authorImage?.filename}
                    alt=""
                  />
                  <p className="text-[#CED8D7] font-medium font-satoshi">
                    {topArticle?.author}
                  </p>
                </div>
              </AnimatedSection>
              <AnimatedSection>
                <p className="text-[#CED8D7] font-bold font-satoshi">
                  {formatDate(topArticle?.date)}
                </p>
              </AnimatedSection>
            </div>
          </div>
        </div>
        <BlogCard
          title="Most read articles"
          activeTab={activeTab}
          blogs={storyblok}
        />

        <BlogCard
          title="Recently added articles"
          activeTab={activeTab}
          blogs={storyblok}
          showRecentlyAdded={true}
        />
      </div>
    </>
  );
};

export const BlogCard = ({
  blogs,
  title,
  activeTab,
  showRecentlyAdded,
}: {
  title?: string;
  blogs: {
    id: number;
    image: {
      filename: string;
    };
    description: any;
    author: string;
    authorImage: string;
    date: string;
    heading: string;
    facebookUrl: string;
    linkedinUrl: string;
    topArticle: boolean;
  }[];
  activeTab?: {
    name: string;
    value: string;
  };
  showRecentlyAdded?: boolean;
}) => {
  const navigate = useNavigate();

  const filterByCategory = (id: any) => {
    const items =
      activeTab?.value === 'all'
        ? blogs
        : blogs?.filter(
            (item: any) =>
              item?.category.toLowerCase() === activeTab?.name.toLowerCase(),
          );
    return items;
  };

  let blogsArray = title ? filterByCategory(activeTab) : blogs;

  if (showRecentlyAdded) {
    blogsArray = blogs
      ?.filter((blog) => !blog.topArticle)
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }

  const filteredOutTopContent = blogsArray?.filter((blog) => !blog.topArticle);

  return (
    <>
      {filteredOutTopContent?.length > 0 ? (
        <div>
          <h2 className="text-4xl font-bold text-[#191B1F] mb-10">{title}</h2>
          <div className="grid gap-8">
            <div className="grid md:grid-cols-2 gap-[56px_30px]">
              {filteredOutTopContent?.map((blog) => (
                <div className="grid gap-3" key={blog.id}>
                  <img
                    className="rounded-xl object-cover w-full h-[258px]"
                    src={blog?.image?.filename}
                    alt=""
                  />
                  <AnimatedSection>
                    <div className="grid gap-3">
                      <h3 className="text-2xl font-bold text-[#191B1F]">
                        {blog.heading}
                      </h3>
                      <hr />
                      <p className="text-base">
                        {formatDate(blog.date)} | By: {blog.author}
                      </p>
                      <p className="text-base max-h-[80px] text-ellipsis overflow-hidden line-clamp-2">
                        {blog?.description?.content[0]?.content[0]?.text}
                      </p>
                      <div className="mt-1">
                        <Button
                          variant="outline-blue"
                          onClick={() => navigate(`/blogs/${blog.id}`)}
                        >
                          <div className="flex gap-3 items-center p-[12px_15px]">
                            Read more
                            <img src={ArrowRight} alt="" />
                          </div>
                        </Button>
                      </div>
                    </div>
                  </AnimatedSection>
                </div>
              ))}
            </div>
            {blogs.length > 6 ? (
              <Button variant="primary-rounded">
                <div className="flex items-center gap-3">
                  <img src={ArrowDownWhite} alt="" />
                  <p className="font-jost">Load More Articles</p>
                </div>
              </Button>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};
