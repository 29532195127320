import React from 'react';
import { Button } from '../Button';
import { Link } from 'react-router-dom';

export const NavButtons = ({ variant }: any) => {
  return (
    <>
      <Link to="https://merifot.enyata.com/auth/login" target="_blank">
        <Button variant={variant ? variant : 'black'}>Log in</Button>
      </Link>
      <Link to="https://merifot.enyata.com/auth/create-account" target="_blank">
        <Button>Sign up</Button>
      </Link>
    </>
  );
};

