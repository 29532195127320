export function getParagraphs(data: any): string[] {
  const arr: string[] = [];
  if (!data) return arr;

  for (const s of data) {
    for (const t of s.content) {
      const str = t.text;
      if (typeof str === 'string') {
        arr.push(str);
      }
    }
  }

  return arr;
}

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };
  return date.toLocaleDateString('en-GB', options);
};
