import {
  AchieveGoals,
  HowItWorks,
  UnleashAchievements,
} from '../LandingPageMainSections';

export const LandingPageMain = () => {
  return (
    <main className="flex flex-col gap-[110px] md:gap-[180px]">
      <AchieveGoals />
      <HowItWorks />
      <UnleashAchievements />
    </main>
  );
};
