const BaseError = ({ error }: { error?: string }) => {
  return (
    <>
      {error ? (
        <p className="error  items-center text-[var(--error)]">{error}</p>
      ) : null}
    </>
  );
};

export { BaseError };
