import { Link, useParams } from 'react-router-dom';
import { Facebook, GeometricBackground, LinkedIn } from '../../assets';
import { AnimatedSection } from '../AnimateSection';
import { BlogCard } from '.';
import { useBlogsStoryBlokData } from '../../hooks/storyblok/useBlogsStoryBlokData';
import { Loader } from '../Loader';

export const BlogArticle = () => {
  const { id } = useParams();

  const { data: blogs } = useBlogsStoryBlokData();

  const otherItems = blogs?.filter((item: { id: string }) => item.id !== id);

  const blogArticle = blogs?.find((item: { id: string }) => item?.id === id);

  if (!blogArticle) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <div className="relative md:auto">
      <img
        className="absolute w-full top-0 left-0 z-0"
        src={GeometricBackground}
        alt=""
      />
      <div className="relative grid gap-8 z-10  pt-[56px] md:pt-[90px]">
        <div className="grid gap-8 max-w-[1240px] m-auto w-full">
          <AnimatedSection>
            <h1 className="uppercase text-sm text-[#BDBDB7] font-bold">
              FLUXTRIBE APP
            </h1>
          </AnimatedSection>
          <AnimatedSection>
            <div className="flex items-end justify-between">
              <h2 className="text-5xl font-black text-[#1A1C1F] whitespace-pre-line max-w-[777px]">
                {blogArticle?.heading}
              </h2>
              <span className="text-[#CED8D7] font-bold font-satoshi">
                {blogArticle?.date}
              </span>
            </div>
          </AnimatedSection>
        </div>
        <AnimatedSection>
          <img
            className="h-[567px] object-cover w-full"
            src={blogArticle?.image?.filename}
            alt=""
          />
        </AnimatedSection>
      </div>
      <div className="relative max-w-[1240px] m-[auto] pt-[56px] md:pt-[90px] z-10">
        <div className="grid md:grid-cols-[max-content_1fr] gap-[134px]">
          <div>
            <AnimatedSection>
              <div className="grid gap-8">
                <div className="flex items-center gap-2">
                  <img
                    className="w-8 h-8 rounded-[50%] font-satoshi"
                    src={blogArticle?.authorImage?.filename || ''}
                    alt=""
                  />
                  <p className="text-[#191B1F] text-base font-medium font-satoshi">
                    {blogArticle?.author}
                  </p>
                </div>
                <Link
                  className="flex items-center gap-2"
                  to={blogArticle?.facebookUrl?.url || ''}
                >
                  <img
                    className="w-8 h-8 rounded-[50%] font-satoshi"
                    src={Facebook}
                    alt=""
                  />
                  <p className="text-[#191B1F] text-base font-medium font-satoshi">
                    Facebook
                  </p>
                </Link>
                <Link
                  className="flex items-center gap-2"
                  to={blogArticle?.linkedinUrl.url || ''}
                >
                  <img
                    className="w-8 h-8 rounded-[50%] font-satoshi"
                    src={LinkedIn}
                    alt=""
                  />
                  <p className="text-[#191B1F] text-base font-medium font-satoshi">
                    Linked In
                  </p>
                </Link>
              </div>
            </AnimatedSection>
          </div>
          <AnimatedSection>
            <p className="whitespace-pre-line text-[#545861] text-base">
              {blogArticle?.description?.content[0]?.content[0]?.text}
            </p>
          </AnimatedSection>
        </div>
      </div>
      <div className="max-w-[1240px] m-auto w-full">
        <h2 className="text-4xl font-bold text-[#191B1F] mt-[100px]">
          Most read articles
        </h2>
        <BlogCard blogs={otherItems} />
      </div>
    </div>
  );
};
