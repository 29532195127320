import { AndroidPhone, AppStore, GooglePlay } from '../../../../assets';

export const FluxTribeOnTheGo = () => {
  return (
    <div className="bg-[#EBF6FE] pt-[33px] md:pt-[44px] px-[20px] lg:pl-[42px] flex flex-col-reverse  md:flex-row justify-between lg:items-center">
      <div>
        <img src={AndroidPhone} alt="" className="w-full h-full" />
      </div>
      <div className="flex flex-col gap-9 md:gap-6 lg:max-w-[449px] w-full">
        <div className="flex flex-col gap-3">
          <p className="heading-700 text-[30px] md:text-4xl leading-[48.6px] text-[#171717] max-w-[417px] w-full">
            <span className="text-[var(--blue)]">Fluxtribe on the go:</span>{' '}
            achieve anywhere
          </p>

          <p className="text-sm md:text-base text-[#545861] leading-5 tracking-[0.02em] md:max-w-[417px] w-full">
            Download the Fluxtribe app and turn every moment into a step toward
            success. Set goals, chat, and win prizes effortlessly.
          </p>
        </div>
        <div className="flex gap-5">
          <button>
            <img src={GooglePlay} alt="" className="w-full h-[46px]" />
          </button>
          <button>
            <img src={AppStore} alt="" className="w-full h-[46px]" />
          </button>
        </div>
      </div>
    </div>
  );
};
