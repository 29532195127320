const BaseError = ({ error }: { error?: string }) => {
  return (
    <>
      {error ? (
        <div className="error grid grid-cols-[24px_1fr] items-center text-[var(--error)]">
          {error}
        </div>
      ) : null}
    </>
  );
};

export { BaseError };
