import { NavLink, useLocation } from 'react-router-dom';
import {
  Close,
  BlackChevronDown,
  Hamburger,
  LogoBlack,
  GreenChevronDown,
} from '../../assets';
import { NavMenu } from './NavMenu';
import { useState } from 'react';
import { NavButtons } from './NavButtons';

export const NavBar = () => {
  const [openNavMenu, closeNavMenu] = useState(false);
  const [openMenu, closeMenu] = useState(false);
  const options = [
    { name: 'Goal Settings', path: 'goal-settings' },
    { name: 'Community & Engagement', path: 'community' },
    { name: 'Leaderboard', path: 'leaderboard' },
  ];
  const { pathname } = useLocation();
  const isFeatures =
    pathname.includes('community') ||
    pathname.includes('leaderboard') ||
    pathname.includes('goal-settings');

  const setClose = () => {
    closeMenu(!openMenu);
  };

  return (
    <div className="bg-[var(--black)] text-sm">
      <div className="flex justify-between md:max-w-[1240px] md:m-auto px-5 py-7">
        <NavMenu />
        <div className="flex gap-3 navbar">
          <NavButtons />
        </div>
        <button className="navbar-hambuger" title="button ">
          <img onClick={() => closeMenu(!openMenu)} src={Hamburger} alt="" />
        </button>
      </div>
      {openMenu ? (
        <div className="fixed w-full top-0 left-0 bg-white z-[100] h-full">
          <div className="flex justify-between p-6">
            <NavLink to="">
              <img src={LogoBlack} alt="" />
            </NavLink>
            <button onClick={() => closeMenu(!openMenu)} title="button ">
              <img src={Close} alt="" />
            </button>
          </div>
          <ul className="navlinks grid gap-2">
            <li onClick={setClose}>
              <NavLink to={'/'}>Home</NavLink>
            </li>
            <li onClick={() => closeNavMenu(!openNavMenu)} className="relative">
              <span
                className={`flex items-center ${isFeatures ? 'text-[var(--primary)]' : ''}`}
              >
                Features{' '}
                <img
                  className={`transition-all ${openNavMenu ? '' : ''}`}
                  src={`${isFeatures ? GreenChevronDown : BlackChevronDown}`}
                  alt=""
                />
              </span>
              {openNavMenu ? (
                <ul className="absolute bg-slate-800 shadow-gray-950 top-10 text-white z-[100] w-[70%] p-[4px_0] rounded-lg max-h-[300px]">
                  {options.map((option) => (
                    <NavLink
                      onClick={setClose}
                      className="grid cursor-pointer hover:bg-slate-100 hover:text-black p-[8px_16px]"
                      key={option.name}
                      to={option.path}
                    >
                      {option.name}
                    </NavLink>
                  ))}
                </ul>
              ) : null}
            </li>
            <li onClick={setClose}>
              <NavLink to={'/blogs'}>Blogs</NavLink>
            </li>
            <li onClick={setClose}>
              <NavLink to={'/faqs'}>FAQs</NavLink>
            </li>
            <li onClick={setClose}>
              <NavLink to={'/contact-us'}>Contact Us</NavLink>
            </li>
            <li onClick={setClose}>
              <NavLink to={'/terms'}>Terms of service</NavLink>
            </li>
            <li>
              <NavLink to={'/privacy-policy'}>Privacy Policy</NavLink>
            </li>
            <li className="grid gap-4 mt-4">
              <NavButtons variant={'outline-black'} />
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
};
