export const TermsData = [
  {
    heading: '1. About',
    contents: [
      '1.1. Fluxtribe (“the Product”) is operated by CORALQUEST Limited (also referred to as “CORALQUEST”, “our”, ‘’us’’ or “we”).',
      '1.2. CORALQUEST is a private limited liability company.',
      '1.3. CORALQUEST provides a platform “Fluxtribe” that enables users to join a team and set specific lifestyle goals for themselves through the Platform. It is designed to encourage and motivate Users to reach their lifestyle goals while also fostering a sense of community and accountability through team collaboration.',
      '1.4. To contact us, please email [support@coralquest.co]',
      '1.5. These terms and conditions govern access to and use of Fluxtribe and the services provided to you by CORALQUEST.',
    ],
  },
  {
    heading: '2. Definition of Keywords',
    contents: [
      'i. Account means the Account that is created on Fluxtribe for you when you input your details into the Platform.',
      'ii. Applicable Law means any law (including statutory and common law), statute, constitution, judgment, treaty, regulation, rule, by-law, order, decree, code of practice, circular, directive, other legislative measure, guidance note, requirement, request or guideline or injunction (whether or not having force of law and, to the extent not having force of law, is generally complied with by persons to whom it is addressed or applied) of or made by any authority, which is binding and enforceable on a specific subject matter.',
      'iii. Content may include, without limitation: general news and information, commentary, research reports, educational material and information and data concerning betting; and any other information, content, services, or software.',
      'iv. Person means individual, sole proprietorship, partnership, joint venture, limited liability company, limited liability partnership, trust, estate, unincorporated organization, association, corporation, institution, or other entity.',
      'v. Platform refers to the Fluxtribe website or application at [domain address]',
      'vi. Product refers to Fluxtribe, a community that enables users to join a team and set specific lifestyle goals for themselves.',
      'vii. Services shall include products, features, Content, tools, images, text, information, data, audio, video, graphics, computer code, software, and other material provided by CORALQUEST on the Platform.',
      'viii. Third Party means any Person other than CORALQUEST and its Affiliates.',
      'ix. Third-Party Provider means any Third Party providing any Content on the Platform.',
      'ixi. User means any person or entity that accesses or uses our Services in any way, whether or not they register an account, including you.',
    ],
  },
  {
    heading: '3. Introductions and Acceptance',
    contents: [
      '3.1. These terms and conditions, together with any documents they expressly incorporate by reference (collectively, these “Terms”) govern your access and use of CORALQUEST’s Fluxtribe (xxxxxxxxx) including any content, component, functionality and/ or service offered on the Platform whether as a guest or registered user of the Platform (a “user”).',
      '3.2. All reference to “you or “your” as applicable, mean a user and/or the Person who accesses, uses and/or participates in Platform in any manner, and such Person’s heirs, assigns, and successors. If you use Platform on behalf of an entity, “you” or “your,” as applicable, means that entity and its affiliates and their respective directors, officers, employees, and agents.',
      '3.3. BY ACCESSING OR USING FLUXTRIBE, YOU INDICATE YOUR ACCEPTANCE OF THE FOLLOWING TERMS ON YOUR OWN BEHALF. IF YOU DO NOT AGREE TO THESE TERMS (OR ANY UPDATES TO OR MODIFIED VERSIONS THEREOF), YOU SHOULD DISCONTINUE USE OF FLUXTRIBE IMMEDIATELY.',
      '3.4. These Terms and Conditions constitute a legal and binding agreement between CORALQUEST and you and by using our Platform, Services and/or Content.',
      '3.5. You understand that these Terms and Conditions legally binds you in the same manner that a signed, written, paper contract does. Also, by making use of the Platform, you may have access to certain Content including the policies referenced herein.',
      '3.6. If you do not agree to these terms, you must not use our Platform.',
      '3.7. Your use of the Platform is governed by the version of the Terms in effect on the date the Platform is accessed by you. CORALQUEST may modify these Terms at any time and without prior notice. You should review the most current version of these Terms by visiting the Fluxtribe Platform and clicking on the Terms and Conditions hyperlink.',
    ],
  },
  {
    heading: '4. Account',
    contents: [
      '4.1. You must register for an Account to utilize our Services and be part of our community through our Platform.',
      '4.2. To be eligible to open an Account, you must be an individual who is at least 18 (eighteen) years old.',
      '4.3. By registering for an Account, you agree that your account information may be shared with other users who are members of our community;',
      '4.4. When registering for an Account, you must:',
      '4.4.1. Provide us all information requested by us and comply with our policies, procedures and any applicable law;',
      '4.4.2. Provide accurate information about yourself;',
      '4.4.3. Create only one account (your own) and use your account for personal purposes on the Platform.',
      '4.5. You are solely responsible for all activities that occur under your Account, password, and username.',
      '4.6. You are solely responsible for maintaining the confidentiality of your password and for restricting access to your mobile device so that others may not access any password protected portion of the Platform using your name, username, or password;',
      '4.7. You may only make changes to your Account with our consent. We may require you to comply with our security procedures before we consent to any changes.',
      '4.8. You will immediately notify us of any unauthorized use of your Account, password, or username, or any other breach of security.',
      '4.9. You will not sell, transfer, or assign your account or any Account rights.',
      '4.10. You may NOT open an account or operate an account on behalf of a business or legal entity.',
    ],
  },
  {
    heading: '5. Platform Features',
    contents: [
      '5.1. Creation of teams',
      '5.1.1. Users can create a team or join an existing team of like-minded individuals to work towards common lifestyle goals.',
      '5.1.2. Users can set specific lifestyle goals that they want to achieve with their team including but not limited to fitness, healthy eating, meditation and educational goals.',
      '5.2. Locking Funds',
      '5.2.1. Users can agree on a specific reward amount that each person sets toward the goal and pool the funds together.',
      '5.2.2. The team can lock in the funds pooled and earn rewards by achieving certain goals as defined by their team.',
      '5.3. Rewards',
      '5.3.1. The first set of people to achieve a milestone can earn the pooled money from all the users.',
      '5.3.2. The reward can either go to one or more persons on the team depending on the rules set by the team.',
      '5.3.3. Users can enjoy special rewards from the Platform such as access to discount codes to specific products or services, discounts on gym membership, etc.',
      '5.4. Progress Tracking',
      '5.4.1. Users can track their progress towards their lifestyle goals and can also see how they are progressing compared to their team members. This will encourage them to stay motivated and to reach their goals.',
      '5.5. Collaboration',
      '5.5.1. Our Platform fosters a sense of community and accountability by enabling team members to compete, collaborate, share tips, and cheer each other on towards their shared goals.',
      '5.5.2. Users will achieve their goals when they have a support system and are held accountable for their progress.',
    ],
  },
  {
    heading: '6. Membership',
    contents: [
      '6.1. Our Platform is designed for individuals who are looking to improve their lifestyle and want to achieve their goals with the help of a supportive community. This includes people who want to improve their fitness, health, wellness, and overall lifestyle habits.',
      '6.2. You are only deemed to have become a member of our community if you are the holder of an Account.',
      '6.3. By becoming a member of our community, you agree to work towards the same goal with the other members of the community in order to achieve the overall objectives of the Platform.',
      '6.4. CORALQUEST reserves the right to set membership fees or require the payment of fees for access to the Platform. Upon failure to pay the relevant fees, we will restrict your access to the use of our Platform, products or services.',
      `6.5. We shall not, in any event, be liable for any damages or losses that are deemed or alleged to have resulted from your membership of our community or been caused by the use of the Platform or its content, including but without limitation to, delays or interruptions in operation or transmission, communications lines failure, any person's use or misuse of Fluxtribe or its content, or any errors or omissions in content in relation thereto.`,
    ],
  },
  {
    heading: '7. Other applicable provisions',
    contents: [
      'These Terms and Conditions are additions to any other agreements between you and CORALQUEST, including any customer or account agreements and any other agreements relating to your use of our Platform. Other terms that may apply to you include:',
      `Our Privacy Policy [insert as link to site's privacy policy].`,
    ],
  },
  {
    heading: '8. Changes/ amendments ',
    contents: [
      'We amend these terms from time to time. Every time you wish to use our Platform, please check these terms to ensure you understand the terms that apply at that time.',
    ],
  },
  {
    heading: '9. Suspension/ withdrawal of services',
    contents: [
      'We do not guarantee that our Platform, or any Content, shall always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our Platform for business and operational reasons. We will give you reasonable notice of any suspension or withdrawal.',
      'You are also responsible for ensuring that all persons who access our Platform through your internet connection are aware of these terms and other applicable terms and conditions, and that they comply with them.',
    ],
  },
  {
    heading: '10. Third party links/ Resources',
    contents: [
      '10.1. Where the Platform contains links to other websites, platforms and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them. We have no control over the contents of those sites or resources.',
      '10.2. Such websites or platforms may contain terms and conditions, privacy provisions, confidentiality provisions, or other provisions that may differ from the terms and conditions applicable to the Platform. Links to other Internet services and platforms are provided solely for the convenience of users. A link to any service or website is not an endorsement of any kind of the service, its content, or its sponsoring organization.',
    ],
  },
  {
    heading: '11. Use of site material',
    contents: [
      '11.1. The content on our Platform is protected by the Nigerian and international copyright laws and treaty provisions, trademarks laws, and other proprietary rights laws. All such rights are reserved. You must not use any part of the content on our Platform for commercial purposes without obtaining the requisite license to do so. ',
      '11.2. Note that the material on Fluxtribe does not constitute professional advice except where expressly communicated to be CORALQUEST’s professional advice, opinion or recommendation.',
    ],
  },
  {
    heading: '12. No Guaranty',
    contents: [
      '12.1. CORALQUEST does not warrant that the Fluxtribe Platform will operate correctly, will be available 100% of the time, will function without interruption and/ or that it is error-free.',
      '12.2. We do not guarantee that our Platform shall be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programmes and platform to access our Platform. You should use your own virus protection software.',
      '12.3. You must not misuse our Platform by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorized access to our Platform, the server on which Fluxtribe is stored or any server, computer or database connected to our Platform. You must not attack our Platform through a denial-of-service attack or a distributed denial-of service attack. We shall report any such breach to the relevant law enforcement authorities and we shall co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our Platform shall cease immediately.',
    ],
  },
  {
    heading: '13. Choice of law and jurisdiction',
    contents: [
      'Please note that these Terms, their subject matter and their formation, are governed by Nigerian law. You agree that all disputes arising from the use of the Platform will be resolved by way of binding arbitration. The Lagos State Multi-Door Courthouse will have exclusive jurisdiction to hear and determine any claims or disputes between you and CORALQUEST which arises directly or indirectly from these Terms and Conditions, or any other document executed and delivered in connection with these Terms and Conditions, the use of the Platform or the Services offered by CORALQUEST. You and CORALQUEST agree to submit to arbitration under Nigerian Law.',
    ],
  },
  {
    heading: '14. Community Guidelines',
    contents: [
      `14.1. We value the members of our community, and we ensure that their welfare and interest is protected on our Platform. Thus, we strictly enforce compliance with our community guidelines. The following practices outlined below are strictly prohibited on our Platform:
      a) Bullying of community members.
      b) Promoting uncouth mannerisms within the community.
      c) Creation of sects or sub-teams for the purposes of disrupting community activities or initiatives.
      d) Unauthorized collation of the personal information of other community members for other purposes outside the objectives of the platform.
      e) Abuse of discounts, incentives or other promotions; and/or
      f) Using unfair external factors or influences (otherwise known as cheating) to win rewards.
      g) Colluding with other individuals using the Services to unduly earn rewards.
      h) Opening any duplicate Accounts; 
      i) Undertaking fraudulent practice or criminal activity within the Platform.
      `,
      '14.2. Any violation of these guidelines will constitute a material breach of the Terms and Conditions. We will take all reasonable steps to prevent and detect such practices and to identify the relevant defaulters concerned if they do occur. Subject to the above, however, we will not be liable for any loss which may occur in our bid to ensure strict compliance, and any action we take in respect of the same will be at our sole discretion.',
      '14.3. If you suspect a person is violating our community guidelines, you shall as soon as reasonably practicable report it to us by e-mailing our customer service team (support@coralquest.co).',
      '14.4. By accepting these Terms and Conditions you agree that you shall not participate in or be connected with any form of activity that will violate our community guidelines.',
      '14.5. You agree that violation of our guidelines may result in permanent removal from our Platform and termination of your access to our Services. To the extent of the damage done, we may also pursue legal action by arbitration or in a court of competent jurisdiction to recover damages sufficient in compensation.',
      `14.5.1. "Fraudulent practice" means any fraudulent activity engaged in by you or by any person acting on your behalf or in collusion with you, and shall include, without limitation:
      (a) Fraudulent charge-backs activity;
      (b) The collusion by you with others in order to gain an unfair advantage (including through discount schemes or similar incentives offered by us);
      (c) Any attempt to register false or misleading account information; and
      (d) Any actual or attempted act by you which is reasonably deemed by us to be illegal in any applicable jurisdiction, made in bad faith, or intended to defraud us and/or circumvent any contractual or legal restrictions, regardless of whether such act or attempted act actually causes us any damage or harm;
      `,
      `14.5.2. "Unfair advantage" shall include, without limitation: the exploitation of a fault, loophole or error in our or any third party's software used by you in connection with the Services; the use of any ‘Bots’ for any other use which would otherwise constitute any other unfair practices; the use of third party software or analysis systems; or the exploitation by you, of an error, in any case either to your advantage and/or to the disadvantage of us or others.`,
    ],
  },
  {
    heading: '15. AML/KYC Compliance and Regulations',
    contents: [
      '15.1. CORALQUEST maintains an internal anti-money laundering and know your customer compliance program (“AML/KYC programme”). The AML/KYC Program is a risk-based programme founded on anti-money laundering laws, regulations and guidelines made by the Central Bank of Nigeria and various financial crimes institutions of the Federal Republic of Nigeria. This AML/KYC Program may be updated from time-to-time, including the procedures that CORALQUEST uses to verify your identity.',
    ],
  },
  {
    heading: '16. Compliance with Laws and Regulations',
    contents: [
      '16.1. CORALQUEST’s policies and terms are subject to and in compliance with the laws of the Federal Republic of Nigeria specifically the regulations of the Nigerian Data Protection Act 2023 as it relates to the services on Fluxtribe.',
    ],
  },
  {
    heading: '17. Fees and Payments',
    contents: [
      'In consideration of our Lock-In feature as we’ll as fees for our Services, you may be required to:',
      '17.1.1. Pay certain fees for the use of our Services. We may require you to provide some of your bank, credit or debit card information to process payment.',
      '17.1.2. Pay all fees charged by any third-party service provider associated with your bill as well as pay any fees charged by CORALQUEST for any transfers.',
      '17.1.3. ensure that any inbound and outbound transfers are handled in compliance with CORALQUEST requirements, third party service provider requirements and Applicable Law.',
      '17.1.4. ensure that there are no errors in any of the transfer instructions you provide while using our Services.',
      '17.1.5. In the event you fail to comply with any requirements of this clause, the transferred funds may be permanently lost.',
      '17.2. The timing for completing any transfer shall depend on third party actions that are outside CORALQUEST’s control, and we make no guarantee regarding the amount of time it may take to complete any transfer.',
    ],
  },
  {
    heading: '18. Your acknowledgements, representations and warranties',
    contents: [
      'The Platform allows you access our goal-achievement platform. By using our Services, you acknowledge, represent and warrant that:',
      '18.1. You are at least 18 years of age and of full legal capacity within your region. Thus, you understand that any access and/or or use of the Services (or any part thereof) by anyone who is not of legal age to form a binding agreement with CORALQUEST, or by anyone who is otherwise legally prohibited or unauthorized to form a binding agreement with CORALQUEST under any Applicable Law, is unlawful and in violation of this Terms and Conditions.',
      '18.2. You are permitted by the Applicable Law to make use of our Services.',
      '18.3. You shall use the platform for legal purposes only.',
      `18.4. CORALQUEST reserves the right, in its sole discretion, to refuse, suspend, block or terminate the Services with respect to any user at any time.`,
      '18.5. You undertake to not expose the Platform to any risk of piracy and attempted attacks on the vulnerability of the platform and its security system, and to implement all appropriate measures to prevent those aforementioned risks or any other risk that may affect the platform.',
      '18.6. All monies you utilize on the Platform were acquired through lawful means only.',
      '18.7. All information and documents that you provide on the Platform is accurate and complete and remains so.',
    ],
  },
  {
    heading: '19. Indemnity',
    contents: [
      `You agree to defend, indemnify and hold CORALQUEST, its respective employees, officers, directors, shareholders, controlling persons, affiliates, agents, successors and assigns (collectively, the "Indemnified Persons") harmless, from and against any loss, liability, claim, damage, expense (including costs of investigation and defense and reasonable attorneys' fees) or diminution of value, whether or not involving a third-party claim (collectively, "Damages"), directly or indirectly, relating to, resulting from or arising out of the breach of any representation or warranty herein. You hereby undertake to pay any Indemnified Person (as the case may be) any amount which is required to be paid by it under this Clause 19 within ten (15) business days from the Indemnified Persons’ notice to absolve you of the liability.`,
    ],
  },
  {
    heading: '20. Limitation of Liability',
    contents: [
      '20.1. UNDER NO CIRCUMSTANCES SHALL CORALQUEST, ITS RESPECTIVE EMPLOYEES, OFFICERS, DIRECTORS, SHAREHOLDERS, CONTROLLING PERSONS, AFFILIATES, AGENTS, SUCCESSORS AND ASSIGNS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, EXEMPLARY, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES, ANY PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS, OR ANY LOSS OF PROFITS, REVENUE, BUSINESS, DATA, USE, GOODWILL OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR IN CONNECTION WITH THIS TERMS AND CONDITIONS, OUR PLATFORM, THE USE OR INABILITY TO USE OUR PLATFORM, ANY CONDUCT, PERFORMANCE OR NON-PERFORMANCE (WHETHER ONLINE OR OFFLINE) OR ANY OTHER USER OR OTHER THIRD PARTY.',
      '20.2. TO THE EXTENT LEGALLY PERMITTED, WE EXCLUDE ALL IMPLIED WARRANTIES, TERMS AND CONDITIONS. WE ARE NOT LIABLE FOR ANY LOSS OF MONEY, GOODWILL, OR REPUTATION, OR ANY SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES ARISING OUT OF YOUR USE OF OUR PLATFORM AND SERVICES.',
      '20.3. NOTWITHSTANDING THE PREVIOUS CLAUSE 20.2, IF WE ARE FOUND TO BE LIABLE, OUR LIABILITY TO YOU OR TO ANY THIRD PARTY IS LIMITED TO THE GREATER OF (A) THE TOTAL FEES YOU PAID TO US IN THE 12 MONTHS PRIOR TO THE ACTION, GIVING RISE TO THE LIABILITY.',
      '20.4. THE EXCLUSIONS AND LIMITATIONS OF LIABILITY PROVIDED IN CLAUSES 20.1 – 20.3 ABOVE, APPLY TO ALL CLAIMS, WHETHER BASED ON WARRANTY, CONTRACT, STATUS, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR ANY OTHER LEGAL CLAIM, WHETHER OR NOT CORALQUEST HAS BEEN ADVISED OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGE OR LOSS, AND EVEN IF A REMEDY SET FORTH IN THIS TERMS AND CONDITIONS IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.',
    ],
  },
  {
    heading: '21. Termination',
    contents: [
      '21.1. Termination of the contract shall be by you deleting your profile and discontinuing use of our Services or by CORALQUEST deleting your profile and discontinuing your use of our Services. Notwithstanding the foregoing, any termination herein shall not affect the rights and obligations accrued prior to the termination.',
      '21.2. You agree that CORALQUEST shall not be liable to you or to any third party for any access, use, modification, suspension or discontinuance of the Services, or any portion thereof.',
      '21.3. Upon any expiration or termination of this Terms and Conditions, you shall promptly cease using the Services.',
    ],
  },
  {
    heading: '22. Miscellaneous',
    contents: [
      '22.1. If any provision (or part of a provision) of this Terms and Conditions is found by any court or administrative body of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall remain in force. If any invalid, unenforceable or illegal provision would be valid, enforceable and legal if some part of it were deleted, the provision shall apply with whatever modification is necessary to give effect to the commercial intention therein.',
      '22.2. No delay or failure by us to exercise or enforce any right or provision of this Terms and Conditions shall be considered a waiver. No right or remedy under this Terms and Conditions shall be considered waived by CORALQUEST unless the waiver is in writing and signed by a representative who intends to and is duly authorized to agree to the waiver on our behalf. No single or partial exercise by us of any right or remedy under this Terms and Conditions shall prevent us from exercising any other right or remedy.',
      "22.3. This agreement is a personal agreement between CORALQUEST and you. As a result, you are not allowed to assign your rights under this agreement to a third party. On the other hand, we can and will only transfer any of your and our rights or obligations under the agreement if we reasonably think that this won't have a significant negative effect on your rights under these Terms or if we need to do so to keep in line with any legal or regulatory requirement.",
      '22.4. You agree to CORALQUEST’s Privacy Policy, which explains how we collect, use and protect the personal information you provide to us.',
    ],
  },
];
